/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NearMeIcon from "@mui/icons-material/NearMe";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import moment from "moment";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

// const ITEM_HEIGHT = 48;
/* eslint-disable */
function List(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [limit, setLimit] = useState(12);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const seeMore = () => {
    setLimit(limit + 12);
  };
  useEffect(() => {
    setLimit(12);
  }, [props.items]);

  const renderProfiles = props.items
    .slice(0, limit)
    .map(
      ({
        item_id,
        item_cat_id,
        item_externalID,
        item_name,
        item_text,
        item_link,
        item_imageURL,
        item_expirationDate,
        item_status,
      }) => (
        <Grid item xs={12} xl={6}>
          <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#f8f9fa" }}>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start">
              {item_status === 0 ? (
                <Tooltip title={"Active"}>
                  <CheckBoxIcon fontSize="small" color="success" />
                </Tooltip>
              ) : (
                <Tooltip title={"Inactive"}>
                  <IndeterminateCheckBoxIcon fontSize="small" color="error" />
                </Tooltip>
              )}
              {moment.utc(item_expirationDate).format("YYYY-MM-DD") <=
                moment.utc().format("YYYY-MM-DD") && (
                <Tooltip title={"Expired"}>
                  <AccessTimeIcon fontSize="small" color="error" />
                </Tooltip>
              )}
            </MDBox>

            <MDBox
              key={item_name}
              component="li"
              display="flex"
              alignItems="center"
              py={1}
              mb={1}
              flexDirection="column"
            >
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <MDTypography
                  component="a"
                  href={item_link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h4"
                  fontWeight="medium"
                  p={2}
                >
                  {item_name}
                </MDTypography>
              </MDBox>
              {item_imageURL && (
                <MDBox
                  component="img"
                  src={item_imageURL}
                  alt={item_name}
                  borderRadius="xs"
                  shadow="md"
                  width="60%"
                  height="60%"
                  position="relative"
                  zIndex={1}
                />
              )}
            </MDBox>
            <Card sx={{ p: 1, boxShadow: "none" }}>
              <MDBox flexDirection="column">
                <MDTypography variant="body" component="p" color="text">
                  {item_text}
                </MDTypography>
              </MDBox>
            </Card>

            <MDBox display="flex" flexDirection="row" alignItems="flex-start">
              <Grid container>
                <Grid item xs="11">
                  {item_expirationDate && (
                    <MDTypography variant="body2" pl={2}>
                      Expires: {moment.utc(item_expirationDate).format("YYYY-MM-DD")}
                    </MDTypography>
                  )}
                  {item_externalID && (
                    <MDTypography variant="body2" pl={2}>
                      External ID: {item_externalID}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs="12">
                  <MDBox display="flex" justifyContent="center" alignItems="center">
                    <IconButton
                      onClick={() => {
                        handleClose();
                        props.onChangeStatus(item_id, item_status);
                      }}
                      sx={{ fontWeight: "bold" }}
                      color="primary"
                      aria-label="prompt"
                      size="small"
                    >
                      <Tooltip title={item_status === 1 ? "Activate" : "Deactivate"}>
                        <IconButton>
                          {item_status === 1 ? <NearMeIcon fontSize="small"/> : <NearMeDisabledIcon fontSize="small"/>}
                        </IconButton>
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        handleClose();
                        props.onEdit(item_id);
                      }}
                      sx={{ fontWeight: "bold" }}
                      color="primary"
                      aria-label="prompt"
                      size="small"
                    >
                      <Tooltip title={"Edit"}>
                        <IconButton>
                          <EditIcon fontSize="small"/>
                        </IconButton>
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        handleClose();
                        props.onDelete(item_id);
                      }}
                      sx={{ fontWeight: "bold" }}
                      color="primary"
                      aria-label="prompt"
                      size="small"
                    >
                      <Tooltip title={"Delete"}>
                        <IconButton>
                          <DeleteIcon color="error" fontSize="small"/>
                        </IconButton>
                      </Tooltip>
                    </IconButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      )
    );

  return (
    <Grid container>
      {renderProfiles}
      {limit < props.items.length && (
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
          <MDButton variant="text" color="info" onClick={() => seeMore()} fullwidth>
            Show more
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
}

// Setting default props for the ProfilesList
List.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default List;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import client from "ApiClient";
import { CircularProgress, Grid, TextField } from "@mui/material";
import List from "./list";
import AddCsv from "./addCsv";
import Add from "./add";
import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [originalItems, setOriginalItems] = useState([]);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    method: "GET",
    url: "catalogs",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `items`;
    options.params = {
      cat_id: props.catId,
    };
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOriginalItems(response);

        if (search != "")
          setItems(
            response.filter((item) => item.item_name.toLowerCase().includes(search.toLowerCase()))
          );
        else setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [props.catId]);

  useEffect(() => {
    getData();
  }, [props.groupAdded]);

  const handleEdit = (id) => {
    setItemSelected(items.filter((item) => item.item_id == id)[0]);

    handleOpenEditModal();
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `items`;
    options.headers = {
      "content-type": "application/json",
    };

    let is = items.filter((item) => item.item_id == id);
    options.data = JSON.stringify(is[0]);

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const changeStatus = (id, status) => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `items`;
    options.headers = {
      "content-type": "application/json",
    };

    let is = items.filter((item) => item.item_id == id)[0];
    is.item_status = status == 0 ? 1 : 0;
    options.data = JSON.stringify(is);

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleSearch = (e) => {
    const filterInput = e.target.value;
    setSearch(filterInput);
    if (filterInput == "") setItems(originalItems);
    else
      setItems(
        items.filter((item) => item.item_name.toLowerCase().includes(filterInput.toLowerCase()))
      );
  };

  return (
    <MDBox pb={3} pt={2}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AddCsv
            openModal={openModal}
            closeModal={handleCloseModal}
            onDone={() => getData()}
            catId={props.catId}
          />

          <Add
            openModal={openAddModal}
            closeModal={handleCloseAddModal}
            onDone={() => getData()}
            catId={props.catId}
          />

          <Edit
            openModal={openEditModal}
            closeModal={handleCloseEditModal}
            onDone={() => getData()}
            itemID={itemSelected.item_id}
            externalID={itemSelected.item_externalID}
            name={itemSelected.item_name}
            text={itemSelected.item_text}
            link={itemSelected.item_link}
            imageURL={itemSelected.item_imageURL}
            expirationDate={itemSelected.item_expirationDate}
            status={itemSelected.item_status}
            catId={props.catId}
          />
          <MDBox
            mx={2}
            mt={1}
            py={3}
            px={2}
            bgColor="badgeColors.primary"
            borderRadius="xs"
            variant="gradient"
          >
            <Grid container>
              <Grid item xs={12} md={10}>
                <MDTypography variant="h2">
                  Items for {props.catName}
                  {isLoading && (
                    <CircularProgress
                      size={30}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
                <MDBox justifyContent="right">

                  <MDBox display="flex" justifyContent="right">
                    <MDButton variant="gradient" color="secondary" onClick={handleClick}>
                      &nbsp;&nbsp; create new &nbsp;&nbsp;
                      <KeyboardArrowDownIcon />
                    </MDButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenAddModal(true);
                        }}
                      >
                        <AddIcon />
                        &nbsp; manually
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenModal(true);
                        }}
                      >
                        <FileUploadIcon />
                        &nbsp; from csv
                      </MenuItem>
                    </Menu>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox>
            <Grid container p={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Search"
                  style={{ backgroundColor: "#FFFFFF" }}
                  value={search}
                  onChange={(event) => handleSearch(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <List
                  items={items}
                  onChangeStatus={(id, status) => changeStatus(id, status)}
                  onEdit={(id) => handleEdit(id)}
                  onDelete={(id) => handleDelete(id)}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

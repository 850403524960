import { useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";

/* eslint-disable */
function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const clearVariables = () => {
    setName("");
    setDescription("");
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `suppression`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        spr_name: name,
        spr_description: description,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  /*
   <Dialog
      open={openModal}
      onClose={clearVariables}
      sx={{
        //You can copy the code below in your theme
        background: "#FFFFFF",
        "& .MuiPaper-root": {
          background: "#FFFFFF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
    */
  return (
    <Dialog open={openModal} onClose={clearVariables}>
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Create a new suppression list
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="List Name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("List name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="List Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("List description", description, "required")}
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={() => handleSave()}
          disabled={isLoading}
          variant="gradient"
          color="success"
        >
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>
    </Dialog>
  );
}
export default Add;

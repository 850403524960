import { useRef, useState, useEffect, Fragment } from "react";
import theme from "assets/theme";
import ImageIcon from "@mui/icons-material/Image";
import UploadIcon from "@mui/icons-material/Upload";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import InputFiles from "react-input-files";
import Moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { useMaterialUIController } from "context";

/* eslint-disable */
function EditArticle(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);
  const [onUpdating, setOnUpdating] = useState(false);
  // const [updateType, setUpdateType] = useState(null); //0:headline,1:intro,2:article
  const [loadingLabel, setLoadingLabel] = useState("");
  const [triggerOffer, setTriggerOffer] = useState(false);
  const [headline, setHeadline] = useState("");
  const [intro, setIntro] = useState("");
  const [article, setArticle] = useState("");
  const [url, setURL] = useState("");
  const [post, setPost] = useState([]);
  // const [contentEditorValue, setContentEditorValue] = useState("");

  const { openModal, closeModal } = props;

  const [file, setFile] = useState(null);
  const [blogId, setBlogId] = useState(0);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedInterest, setSelectedInterests] = useState(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(0);
  const editorRef = useRef(null);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setIsLoadingPreview(false);
    setIsUploading(false);
    setIsReadyToPublish(false);
    setOnUpdating(false);
    setLoadingLabel("");
    setTriggerOffer(false);
    setHeadline("");
    setIntro("");
    setArticle("");
    setURL("");
    setPost([]);
    closeModal();
  };

  useEffect(() => {
    if (props.blaId > 0) getOffers();
  }, [props.blaId]);

  useEffect(() => {
    if (selectedSubtopic != "") getSubtopicContent();
  }, [selectedSubtopic]);

  useEffect(() => {
    if (selectedInterest != null) getTopics();
  }, [selectedInterest]);

  /*
  useEffect(() => {
    setSelectedCategory(0);
  }, [categories]);
  */

  const getArticle = () => {
    setIsLoadingPreview(true);
    setLoadingLabel("Loading article");
    const options = {
      method: "Get",
      url: `blogArticles/GetById?article_id=` + props.blaId,
    };

    client
      .request(options)
      .then((response) => {
        setIsLoadingPreview(false);
        setIsReadyToPublish(true);
        setBlogId(response.bla_blog_id);
        setURL(response.articleURL);
        setPost(response.post);
        setHeadline(response.headline);
        setIntro(response.introduction);
        setArticle(response.articleHTML);
        setCategories(response.categories);
        setSelectedCategory(response.article_category);
        setSelectedTopic(response.topic);
        setTriggerOffer(response.bla_blo_id == 0 ? false : true);
        offers.length > 0
          ? setSelectedOffer(response.bla_blo_id == 0 ? offers[0].blo_id : response.bla_blo_id)
          : setSelectedOffer(0);
      })
      .catch((error) => {
        setIsLoadingPreview(false);
        console.log(error);
      });
  };

  const getOffers = async () => {
    const options = {
      method: "GET",
    };
    options.url = `blogOffers`;
    await client
      .request(options)
      .then((response) => {
        setOffers(response);
        if (response.length > 0) setSelectedOffer(response[0].blo_id);
        if (props.blaId > 0) getArticle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeEditor = (content) => {
    setArticle(content);
    //  console.log(content); //Get Content Inside Editor
  };

  const handleChangeIntro = (event) => {
    setIntro(event.target.value);
  };

  const handleSave = async () => {
    setOnUpdating(true);
    setLoadingLabel("Publishing article");
    const options = {
      method: "POST",
      url: `blogArticles/RefreshPreview`,
      headers: {
        "content-type": "application/json",
      },
    };

    post.categories = [selectedCategory];
    options.data = JSON.stringify({
      post: post,
      contentHtml: article,
      Title: headline,
      Introduction: intro,
      imageFilename: "",
      save: true,
      topic: selectedTopic,
      blog_id: blogId,
      bla_blo_id: triggerOffer ? selectedOffer : 0,
    });

    client
      .request(options)
      .then((response) => {
        setOnUpdating(false);
        //console.log(response);
        props.closeModal();
        // closeModal();
        // setHeadline(response.headline);
        //setIntro(response.introduction);
        //setArticle(response.post.content.raw);
        //setPost(response.post);
        //setURL(response.articleURL + "?h=" + Moment().format("YYYYMMDDHHmmss"));
      })
      .catch((error) => {
        setOnUpdating(false);
        console.log("ERROR", error);
      });
  };

  const previewArticle = () => {
    setIsLoadingPreview(true);
    setLoadingLabel("Loading preview");
    const options = {
      method: "POST",
      url: `blogArticles/RefreshPreview`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      post: post,
      contentHtml: article,
      Title: headline,
      Introduction: intro,
      imageFilename: "",
      save: false,
      topic: selectedTopic,
      blog_id: blogId,
      bla_blo_id: triggerOffer ? selectedOffer : 0,
    });

    client
      .request(options)
      .then((response) => {
        setIsLoadingPreview(false);
        setURL(response.articleURL + "?h=" + Moment().format("YYYYMMDDHHmmss"));
        setPost(response.post);
        setCategories(response.categories);
        setIsReadyToPublish(true);
      })
      .catch((error) => {
        setIsLoadingPreview(false);
        console.log(error);
      });
  };

  const onFileChange = (event) => {
    setFile(event[0]);
  };

  const uploadImage = () => {
    setIsUploading(true);
    setLoadingLabel("Uploading image and generating preview");
    const options = {
      method: "POST",
      url: `imageUpload`,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("post_id", post.id);

    options.data = formData;
    client
      .request(options)
      .then((response) => {
        setIsUploading(false);
        setPost(response);
        setURL(url + "?h=" + Moment().format("YYYYMMDDHHmmss"));
      })
      .catch((error) => {
        setIsUploading(false);
        console.log(error);
      });
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeHeadline = (event) => {
    setHeadline(event.target.value);
  };

  const handleChangeOffer = (event) => {
    setSelectedOffer(event.target.value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="xs"
        coloredShadow="info"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Edit a Blog Article
        </MDTypography>
      </MDBox>

      <DialogContent>
        <MDBox
          p={5}
          sx={{
            //You can copy the code below in your theme
            background: "#FFFFFF",
            "& .MuiPaper-root": {
              background: "#FFFFFF",
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent", // Try to remove this to see the result
            },
          }}
        >
          <Grid mb={2} container spacing={4}>
            <Grid item xs={10}>
              <TextField
                type="text"
                label="Headline"
                fullWidth
                multiline
                value={headline}
                onChange={handleChangeHeadline}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                type="text"
                label=" Introduction paragraph"
                fullWidth
                multiline
                value={intro}
                onChange={handleChangeIntro}
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                apiKey="9tgbg46laoyq2e9c3x41umzy6i0vgy4stjcr25g7b0sednhu"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={article}
                onEditorChange={handleChangeEditor}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: "advlist code anchor fullscreen table lists link image ",
                  toolbar:
                    "undo redo | fullscreen code | styles | fontfamily fontsize | bold italic underline strikethrough | align |link image | bullist numlist | table tabledelete | emoticons",
                  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => previewArticle()}
                mr={2}
                disabled={onUpdating}
              >
                Preview article
              </MDButton>
              {isLoadingPreview && (
                <MDBox display="flex">
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                  <MDTypography variant="h6" fontWeight="medium" ml={1}>
                    {loadingLabel}...
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </Grid>

          {url != "" && (
            <Fragment>
              <MDBox mb={2}>
                <iframe
                  src={url}
                  height="600"
                  width="100%"
                  allowFullScreen
                  style={{ border: "none" }}
                />
              </MDBox>
              <Grid m={2} container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Upload an image related to this article
                  </MDTypography>
                  <InputFiles
                    onChange={(file) => onFileChange(file)}
                    disabled={isUploading}
                    accept="image/*"
                  >
                    <IconButton color="black" aria-label="upload">
                      <Tooltip id="button-report" title="Upload image">
                        <ImageIcon />
                      </Tooltip>
                      <MDTypography variant="h6" fontWeight="medium" ml={1}>
                        Pick a image to upload
                      </MDTypography>
                    </IconButton>
                  </InputFiles>
                  <MDButton
                    onClick={() => uploadImage()}
                    disabled={isUploading || file == null}
                    mb={2}
                    color="info"
                    variant="contained"
                    endIcon={<UploadIcon />}
                  >
                    Upload
                  </MDButton>
                  {isUploading && (
                    <MDBox display="flex">
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                      <MDTypography variant="h6" fontWeight="medium" ml={1}>
                        {loadingLabel}...
                      </MDTypography>
                    </MDBox>
                  )}
                </Grid>
                <Grid item xs={12} mt={5}>
                  <MDBox display="flex">
                    <MDTypography variant="h6" fontWeight="medium" mr={2} ml={1}>
                      Select a category to publish the article
                    </MDTypography>
                    {categories.length > 0 && (
                      <Select
                        onChange={handleChangeCategory}
                        value={selectedCategory}
                        label="Pick a category"
                      >
                        {categories.map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </MDBox>
                </Grid>
                {false && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="end"
                      labelPlacement="end"
                      control={
                        <Switch
                          checked={triggerOffer}
                          onChange={() => setTriggerOffer(!triggerOffer)}
                        />
                      }
                      label={
                        <MDTypography variant="h5" fontWeight="medium">
                          Email trigger
                        </MDTypography>
                      }
                    />
                  </Grid>
                )}
                {false && triggerOffer && (
                  <Grid item>
                    <MDTypography variant="h6" fontWeight="medium">
                      When clicked or navigate this article, send the following offer
                    </MDTypography>
                    {offers != null && (
                      <Select
                        onChange={handleChangeOffer}
                        value={selectedOffer}
                        label="Pick a category"
                      >
                        {offers.map((item, index) => (
                          <MenuItem key={item.blo_id} value={item.blo_id}>
                            {item.blo_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                )}
              </Grid>
            </Fragment>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          autoFocus
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={handleSave}
          disabled={!isReadyToPublish}
          autoFocus
          variant="gradient"
          color="success"
        >
          Publish
        </MDButton>
        {onUpdating && (
          <MDBox display="flex">
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h6" fontWeight="medium" ml={1}>
              {loadingLabel}...
            </MDTypography>
          </MDBox>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EditArticle;

import { CircularProgress, Grid, IconButton, Tooltip, Select } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Card, ListItemText, MenuItem } from "@material-ui/core";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MUIDataTable from "mui-datatables";
import client from "ApiClient";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDSnackbar from "components/MDSnackbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import MDButton from "components/MDButton";
import CustomToolbarSelect from "./customToolbarSelect";

/* eslint-disable */
export default function Replies() {
  const [unsubscribeSB, setUnsubscribeSB] = useState(false);
  const [deleteSB, setDeleteSB] = useState(false);
  const [reaId, setReaId] = useState(-1); // [1
  const [accounts, setAccounts] = useState([]); // [2
  const [reply, setReply] = useState(false);
  const [repliesData, setRepliesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sendingReply, setSendingReply] = useState(false);
  const [previewReply, setPreviewReply] = useState("");
  const [replyBody, setReplyBody] = useState("");
  const [selectedReply, setSelectedReply] = useState();
  const closeUnsubscribeSB = () => setUnsubscribeSB(false);
  const closeDeleteSB = () => setDeleteSB(false);
  let repliesSelected = [];
  const editorRef = useRef(null);

  const options = {
    method: "GET",
    url: "replies",
  };

  const getDateValue = (date) => {
    return moment(date).format("L");
  };

  const getAccounts = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `replyAccounts`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setAccounts(response);
        if (response.length > 0) {
          setReaId(response[0].rea_id);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = "replies?rea_id=" + reaId;

    await client
      .request(options)
      .then((response) => {        
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setRepliesData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getRepliesCount = () => {
    const options = {
      method: "GET",
      url: `replies/repliesCount`,
      headers: {
        "content-type": "application/json",
      },
    };

    client
      .request(options)
      .then((response) => {
        localStorage.setItem("repliesCount", response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    getAccounts();
    getRepliesCount();
  }, []);

  useEffect(() => {
    if (reaId != -1) getData();
  }, [reaId]);

  const replyMessage = () => {
    setSendingReply(true);
    const options = {
      method: "POST",
      url: `replies/Reply`,
      headers: {
        "content-type": "application/json",
      },
    };
    const replyToSend = selectedReply;
    replyToSend.htmlbody = editorRef.current.getContent();
    options.data = JSON.stringify(replyToSend);

    client
      .request(options)
      .then((response) => {
        if (response == "Sent") {
          setReply(false);
          setReplyBody(selectedReply.htmlbody);
          setSendingReply(false);
        } else {
          setReplyBody(response);
          setSendingReply(false);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "From",
      name: "from",
      options: {
        filter: true,
      },
    },
    {
      label: "Subject",
      name: "subject",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Date",
      name: "date",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
  ];

  const handleUnsubscribe = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `replies`;
    options.data = JSON.stringify(repliesSelected);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then(() => {
        setUnsubscribeSB(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `replies/DeleteReplies?rea_id=${reaId}`;
    const repliesToDelete = repliesSelected.map((reply) => reply.uid);
    options.data = repliesToDelete;
    
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        console.log(response);
        setDeleteSB(true);
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const customToolbar = () => (
    <CustomToolbarSelect onDelete={handleDelete} onUnsubscribe={handleUnsubscribe} />
  );

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any reply",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "replie selected",
      },
    },
    downloadCsv: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "replies.csv", separator: "," },
    onRowClick: (rowData, index) => {
      setPreviewReply(repliesData[index.dataIndex].htmlbody);
      setReplyBody(repliesData[index.dataIndex].htmlbody);
      setSelectedReply(repliesData[index.dataIndex]);
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      repliesSelected = [];
      allRowsSelected.map((row) => {
        repliesSelected.push(repliesData[row.dataIndex]);
      });
    },
    customToolbarSelect: customToolbar,
  };

  const showUnsubscribeSB = (
    <MDSnackbar
      color="success"
      icon="success"
      title="Unsubscribed"
      content="Email address succesfully unsubscribed"
      dateTime=""
      open={unsubscribeSB}
      onClose={closeUnsubscribeSB}
      close={closeUnsubscribeSB}
      bgWhite
    />
  );

  const showDeleteSB = (
    <MDSnackbar
      color="success"
      icon="success"
      title="Deleted"
      content="Replies has been succesfully deleted"
      dateTime=""
      open={deleteSB}
      onClose={closeDeleteSB}
      close={closeDeleteSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox pt={3}>
              <MDBox
                mx={2}
                mt={1}
                py={3}
                px={2}
                bgColor="badgeColors.primary"
                borderRadius="xs"
                variant="gradient"
              >
                <MDTypography variant="h2">
                  Replies{" "}
                  <IconButton onClick={() => getData()} sx={{ fontWeight: "bold" }}>
                    <Tooltip id="button-refresh" title="Refresh replies">
                      {!isLoading ? (
                        <RefreshIcon />
                      ) : (
                        <CircularProgress size={24} style={{ position: "relative" }} />
                      )}
                    </Tooltip>
                  </IconButton>
                  {accounts.length > 0 && (
                    <MDBox mb={2}>
                      <Select
                        placeholder="Select the reply account"
                        value={reaId}
                        onChange={(e) => {
                          setReaId(e.target.value);
                        }}
                      >
                        {accounts.map((item) => (
                          <MenuItem key={item.rea_id} value={item.rea_id}>
                            <ListItemText primary={item.rea_email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                  )}
                </MDTypography>
              </MDBox>
              <Grid container p={2}>
                <Grid item xs={12}>
                  <div style={{ height: "100%", width: "100%" }}>
                    <MUIDataTable data={repliesData} columns={columns} options={tableOptions} />
                  </div>
                </Grid>
                {previewReply != "" && (
                  <Grid container mt={5} style={{ backgroundColor: "#FFF" }} p={5}>
                    <Grid item mb={2} xs={12}>
                      <MDButton onClick={() => setReply(!reply)} color="secondary">
                        Reply
                      </MDButton>
                    </Grid>
                    {reply && (
                      <Card style={{ padding: 10, width: "100%" }}>
                        <Grid item mb={2} xs={12}>
                          <Editor
                            apiKey="9tgbg46laoyq2e9c3x41umzy6i0vgy4stjcr25g7b0sednhu"
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            value={replyBody}
                            onEditorChange={(e) => setReplyBody(e)}
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: "advlist code anchor fullscreen table lists link image ",
                              toolbar:
                                "undo redo | fullscreen code | styles | fontfamily fontsize | bold italic underline strikethrough | align |link image | bullist numlist | table tabledelete | emoticons",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        </Grid>
                        <Grid item mb={2} xs={12}>
                          <MDButton
                            onClick={() => replyMessage()}
                            color="success"
                            disable={sendingReply}
                          >
                            Send{" "}
                            {sendingReply && (
                              <CircularProgress size={24} style={{ position: "relative" }} />
                            )}
                          </MDButton>
                        </Grid>
                      </Card>
                    )}
                    <Grid item mb={2} xs={12}>
                      <iframe
                        srcDoc={previewReply}
                        height="600"
                        width="100%"
                        allowFullScreen
                        style={{ border: "none" }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {showUnsubscribeSB}
      {showDeleteSB}
    </DashboardLayout>
  );
}

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import BlogArticles from "layouts/blogArticles";
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import InterestsIcon from "@mui/icons-material/Interests";
import client from "ApiClient";
import ConfirmationAlert from "components/ConfirmationAlert";
import { Card, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import Interests from "layouts/interests";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openInterest, setOpenInterest] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [blogId, setBlogId] = useState();
  const [blogName, setBlogName] = useState();
  const [openLog, setOpenLog] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "blogs",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `blogs`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setBlogs(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (catalogs.length > 0) getCatalogs();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog && catalogs.length > 0) {
      getData();
    }
  }, [openLog]);

  useEffect(() => {
    getCatalogs();
  }, []);

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `blogs`;
    options.data = JSON.stringify(selectedBlog[0]);
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };
    await client
      .request(options)
      .then((response) => {
        setCatalogs(response);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      label: " ",
      name: "blog_logoURL",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return <MDAvatar size="xxl" src={value} alt={value} />;
        },
      },
    },
    {
      label: "Friendly name",
      name: "blog_friendlyName",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "URL",
      name: "blog_url",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },

    {
      label: "Catalog",
      name: "blog_cat_id",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return catalogs.filter((cat) => cat.cat_id === value)[0].cat_name;
        },
      },
    },
    {
      label: " ",
      name: "blog_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedBlog(blogs[dataIndex]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedBlog(blogs[dataIndex]);
                  setOpenInterest(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit interest">
                  <InterestsIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any blogs",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "blog/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogs.csv", separator: "," },
    onRowClick: (rowData, index) => {
      setBlogId(blogs[index.dataIndex].blog_id);
      setBlogName(blogs[index.dataIndex].blog_friendlyName);
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let blogsSelected = [];
      // emailsSelected.push(acconuntsData[curRowSelected[0].dataIndex]);
      allRowsSelected.map((row) => {
        blogsSelected.push(blogs[row.dataIndex]);
      });
      setSelectedBlog(blogsSelected);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this blog? The catalog associated with it and all its articles will be deleted."
      />
      <Interests
        openModal={openInterest}
        onClose={() => setOpenInterest(false)}
        blogID={selectedBlog.blog_id}
        blogName={selectedBlog.blog_friendlyName}
      />

      <Edit
        openModal={openEdit}
        onClose={() => setOpenEdit(false)}
        blog={selectedBlog}
        catalogs={catalogs}
        onDone={() => getData()}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="xs"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Blogs
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new blog">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  Create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={blogs} columns={columns} options={tableOptions} />
            </div>
          </Grid>
          {blogId && (
            <Grid item xs={12} pt={2}>
              <div style={{ height: "100%", width: "100%" }}>
                <Card>
                  <BlogArticles blogId={blogId} blogName={blogName} />
                </Card>
              </div>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </div>
  );
}

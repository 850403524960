import { useState, useEffect } from "react";
import theme from "assets/theme";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMaterialUIController } from "context";

/* eslint-disable */
function Edit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [onUpdating, setOnUpdating] = useState(false);
  const [expires, setExpires] = useState(false);
  const [margin, setMargin] = useState(300);
  // const [updateType, setUpdateType] = useState(null); //0:headline,1:intro,2:article
  const [loadingLabel, setLoadingLabel] = useState("");
  const [itemID, setItemID] = useState(props.itemID);
  const [externalID, setExternalID] = useState(props.externalID);
  const [name, setName] = useState(props.name);
  const [text, setText] = useState(props.text);
  const [link, setLink] = useState(props.link);
  const [imageURL, setImageURL] = useState(props.imageURL);
  const [expirationDate, setExpirationDate] = useState(props.expirationDate);
  const [status, setStatus] = useState(props.status);
  const { openModal, closeModal } = props;

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  useEffect(() => {
    setExpires(props.expirationDate == null ? false : true);
    setItemID(props.itemID);
    setExternalID(props.externalID);
    setName(props.name);
    setText(props.text);
    setLink(props.link);
    setStatus(props.status);
    setImageURL(props.imageURL);
    setExpirationDate(props.expirationDate);
  }, [props]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setExpires(false);
    setExternalID("");
    setName("");
    setText("");
    setLink("");
    setImageURL("");
    setExpirationDate("");
    setStatus(0);
    closeModal();
  };

  const handleSave = async () => {
    setOnUpdating(true);
    const options = {
      method: "PUT",
      url: `items`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      item_id: itemID,
      item_cat_id: props.catId,
      item_externalID: externalID,
      item_name: name,
      item_text: text,
      item_link: link,
      item_imageURL: imageURL,
      item_expirationDate: expires ? expirationDate : null,
      item_status: status,
    });

    client
      .request(options)
      .then((response) => {
        setOnUpdating(false);
        props.onDone();
        props.closeModal();
      })
      .catch((error) => {
        setOnUpdating(false);
        console.log("ERROR", error);
      });
  };

  return (
    <Dialog
      style={{ marginLeft: margin }}
      open={openModal}
      fullScreen
      onClose={(e, reason) => clearVariables(reason)}
      disableEscapeKeyDown
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Edit an item
        </MDTypography>
      </MDBox>

      <DialogContent>
        <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
          <MDBox component="form" role="form">
            <MDBox mb={2} mt={2} ml={2} mr={2}>
              <MDBox pb={2} display="flex" justifyContent="left">
                <MDInput
                  type="text"
                  label="Item name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <MDInput
                  type="text"
                  label="Text"
                  multiline
                  fullWidth
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Link"
                  fullWidth
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <MDInput
                  type="text"
                  label="Image URL"
                  fullWidth
                  value={imageURL}
                  onChange={(e) => setImageURL(e.target.value)}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <Switch checked={expires} onChange={() => setExpires(!expires)} />
                <MDTypography>Set expiration date</MDTypography>
              </MDBox>
              {expires && (
                <MDBox pb={2} display="flex" justifyContent="left">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      required
                      renderInput={(props) => <TextField {...props} />}
                      label="Select the expiration date "
                      value={expirationDate}
                      onChange={(newValue) => {
                        setExpirationDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </Card>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        {onUpdating && (
          <MDBox display="flex">
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h6" fontWeight="medium" ml={1}>
              {loadingLabel}...
            </MDTypography>
          </MDBox>
        )}
        <MDButton
          autoFocus
          onClick={closeModal}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} autoFocus variant="gradient" color="success">
          Update
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Edit;

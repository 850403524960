import {
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Select, TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import client from "ApiClient";
import StatusPanel from "components/StatusPanel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import TimezoneSelect from "react-timezone-select";
import CustomToolbarTrigger from "./customToolbarTrigger";
import HtmlBlocks from "./htmlBlocks";

/* eslint-disable */
export default function Settings() {
  const [isLoading, setIsLoading] = useState(false);
  const [renewApi, setRenewApi] = useState(false);
  const [isSavingWordpress, setIsSavingWordpress] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [passMauticVisible, setPassMauticVisible] = useState(false);
  const [setId, setSetId] = useState("");
  const [userId, setUserId] = useState("");

  const [gptApiKey, setGptApiKey] = useState("");
  const [isRunning, setIsRunning] = useState("");
  const [unsubscribeUrl, setUnsubscribeUrl] = useState("");
  const [unsubscribeHTML, setUnsubscribeHTML] = useState("");
  const [fromDomain, setFromDomain] = useState("");
  const [totalDailySends, setTotalDailySends] = useState(100);
  const [noEngagementLimitSends, setNoEngagementLimitSends] = useState("");
  const [initialHourOfDay, setInitialHourOfDay] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [companyCountry, setCompanyCountry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [contactUsURL, setContactUsURL] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());

  const [openAlert, setOpenAlert] = useState(false);
  const closeAlert = () => setOpenAlert(false);

  const [apiKey, setApiKey] = useState("");
  const [activeCampaignURL, setActiveCampaignURL] = useState("");
  const [activeCampaignApiKey, setActiveCampaignApiKey] = useState("");
  const [mauticURL, setMauticURL] = useState("");
  const [mauticUserName, setMauticUserName] = useState("");
  const [mauticPassword, setMauticPassword] = useState("");
  const [amazonSESAccessKey, setAmazonSESAccessKey] = useState("");
  const [amazonSESSecretAccessKey, setAmazonSESSecretAccessKey] = useState("");
  const [amazonSESRegionEndpoint, setAmazonSESRegionEndpoint] = useState("");
  const [requestApproval, setRequestApproval] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);

  const [addTrigger, setAddTrigger] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [selectedURLMethod, setSelectedURLMethod] = useState(0);
  const [triggerTargetURL, setTriggerTargetURL] = useState("");
  const [triggerBody, setTriggerBody] = useState("");
  const [selectedTriggerEvent, setSelectedTriggerEvent] = useState(0);
  const [selectedTriggerAction, setSelectedTriggerAction] = useState(0);
  const [triggersData, setTriggersData] = useState([]);
  const [selectedTrigger, setSelectedTrigger] = useState({});
  const [emaId, setEmaId] = useState(0);
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSerie] = useState("");

  const [search, setSearch] = useState("");
  const [originalItems, setOriginalItems] = useState([]);
  const [ItemsFilteredData, SetItemsFilteredsData] = useState([]);
  const [selectedItem, SetSelectedItem] = useState(0);
  const [selectedItemName, SetSelectedItemName] = useState("Any");
  const [selectItem, setSelectItem] = useState(false);

  const [CatalogsData, SetCatalogsData] = useState([]);
  const [selectedCatalog, SetSelectedCatalog] = useState(0);

  const [broadcastData, SetBroadcastData] = useState([]);
  const [BroadcastFilteredData, SetBroadcastFilteredData] = useState([]);
  const [selectBroadcast, setSelectBroadcast] = useState(false);
  const [selectedBroadcast, SetSelectedBroadcast] = useState(0);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  let triggersSelected = [];

  const [triggerEvent] = useState([
    { event: "CLICKS ON THE FOLLOWING ITEM(s)", value: 0 },
    { event: "OPENS", value: 1 },
    { event: "UNSUBSCRIBES", value: 2 },
    { event: "BOUNCES", value: 3 },
    { event: "COMPLAINTS", value: 4 },
    { event: "REACHES THE NO ENGAGEMENT LIMIT", value: 30 },
    { event: "FINISHES THIS SERIE", value: 40 },
  ]);

  const [triggerActions] = useState([
    { action: "COPY TO SERIE", value: 0 },
    { action: "MOVE TO SERIE", value: 1 },
    { action: "REMOVE FROM SERIE", value: 2 },
    { action: "CALL URL", value: 3 },
    { action: "SEND BROADCAST", value: 4 },
  ]);

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Validation failed"
      content="Please review al fields"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const options = {
    method: "GET",
    url: "settings",
    headers: {
      "content-type": "application/json",
    },
  };

  const getTriggers = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `settings/getTriggers`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setTriggersData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getItems = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `items`;
    options.params = {
      cat_id: selectedCatalog,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOriginalItems(response);
        if (search != "")
          SetItemsFilteredsData(
            response.filter((item) => item.item_name.toLowerCase().includes(search.toLowerCase()))
          );
        else SetItemsFilteredsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `catalogs`;

    await client
      .request(options)
      .then((response) => {
        SetCatalogsData(response);
        if (response.length > 0) SetSelectedCatalog(response[0].cat_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDeleteTriggers = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `settings/deleteTriggers`;
    options.data = JSON.stringify(triggersSelected);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then(() => {
        setIsLoading(false);
        getTriggers();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleFilterItem = (e) => {
    const filterInput = e.target.value;
    setSearch(filterInput);
    if (filterInput == "") SetItemsFilteredsData(originalItems);
    else
      SetItemsFilteredsData(
        items.filter((item) => item.item_name.toLowerCase().includes(filterInput.toLowerCase()))
      );
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `settings`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setApiKey(response.apiKey);
        setGptApiKey(response.set_gptApiKey);
        setUnsubscribeUrl(response.set_unsubscribePageURL);
        setFromDomain(response.set_fromDomain);
        setTotalDailySends(response.set_totalDailySends);
        setSetId(response.set_id);
        setUserId(response.set_user_id);
        setNoEngagementLimitSends(response.set_noEngagementLimit);
        setInitialHourOfDay(response.set_preferredHour);
        setCompanyName(response.set_companyName);
        setCompanyCountry(response.set_companyCountry);
        setCompanyState(response.set_companyState);
        setCompanyCity(response.set_companyCity);
        setCompanyAddress(response.set_companyAddress);
        setContactUsURL(response.set_contactUsURL);
        setMauticURL(response.set_mauticURL);
        setMauticUserName(response.set_mauticUsername);
        setMauticPassword(response.set_mauticPassword);
        setIsRunning(response.set_running);
        setActiveCampaignURL(response.set_activeCampaignURL);
        setActiveCampaignApiKey(response.set_activeCampaignApiKey);
        setUnsubscribeHTML(response.set_unsubscribeHTML);
        setAmazonSESAccessKey(response.set_amazonSESAccessKey);
        setAmazonSESSecretAccessKey(response.set_amazonSESSecretAccessKey);
        setAmazonSESRegionEndpoint(response.set_amazonSESRegionEndpoint);
        setSelectedTimezone(response.set_timezone);
        setEmaId(response.set_ema_id);
        setRequestApproval(response.set_requestApproval);
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error.response.status);
      });
  };

  const handleSaveWordpress = async () => {
    setIsSavingWordpress(true);
    options.method = "PUT";
    options.url = `settings`;
    options.data = JSON.stringify({
      set_id: setId,
      set_user_id: userId,
      set_gptApiKey: gptApiKey,
      set_unsubscribePageURL: unsubscribeUrl,
      set_fromDomain: fromDomain,
      set_totalDailySends: totalDailySends,
      set_unsubscribeHTML: unsubscribeHTML,
      set_running: isRunning,
      set_companyName: companyName,
      set_companyCountry: companyCountry,
      set_companyState: companyState,
      set_companyCity: companyCity,
      set_companyAddress: companyAddress,
      set_contactUsURL: contactUsURL,
      set_noEngagementLimit: noEngagementLimitSends,
      set_preferredHour: initialHourOfDay,
      set_articlesSuggestionHTML: "",
      set_mauticURL: mauticURL,
      set_mauticPassword: mauticPassword,
      set_mauticUsername: mauticUserName,
      set_activeCampaignApiKey: activeCampaignApiKey,
      set_activeCampaignURL: activeCampaignURL,
      set_amazonSESAccessKey: amazonSESAccessKey,
      set_amazonSESSecretAccessKey: amazonSESSecretAccessKey,
      set_amazonSESRegionEndpoint: amazonSESRegionEndpoint,
      set_timezone: selectedTimezone,
      set_ema_id: emaId,
      set_requestApproval: requestApproval,
    });

    await client
      .request(options)
      .then(() => {
        setIsSavingWordpress(false);
        setOpenAlert(true);
      })
      .catch((error) => {
        setIsSaving(false);
        // eslint-disable-next-line
      });
  };

  const unsubscribeUrlChange = (event) => {
    setUnsubscribeUrl(event.target.value);
  };

  const fromDomainChange = (event) => {
    setFromDomain(event.target.value);
  };

  const totalDailySendsChange = (event) => {
    setTotalDailySends(event.target.value);
  };

  const companyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const companyCountryChange = (event) => {
    setCompanyCountry(event.target.value);
  };

  const companyStateChange = (event) => {
    setCompanyState(event.target.value);
  };

  const companyCityChange = (event) => {
    setCompanyCity(event.target.value);
  };

  const companyAddressChange = (event) => {
    setCompanyAddress(event.target.value);
  };

  const contactUsURLChange = (event) => {
    setContactUsURL(event.target.value);
  };

  const onChangeMauticURL = (event) => {
    setMauticURL(event.target.value);
  };

  const mauticPasswordChange = (event) => {
    setMauticPassword(event.target.value);
  };

  const mauticUsernameChange = (event) => {
    setMauticUserName(event.target.value);
  };

  const onChangeActiveCampaignURL = (event) => {
    setActiveCampaignURL(event.target.value);
  };

  const onChangeActiveCampaignApiKey = (event) => {
    setActiveCampaignURL(event.target.value);
  };
  const onChangeUnsubscribeHTML = (event) => {
    setUnsubscribeHTML(event.target.value);
  };
  const amazonSESAccessKeyChange = (event) => {
    setAmazonSESAccessKey(event.target.value);
  };
  const amazonSESRegionEndpointChange = (event) => {
    setAmazonSESRegionEndpoint(event.target.value);
  };

  const amazonSESSecretAccessKeyChange = (event) => {
    setAmazonSESSecretAccessKey(event.target.value);
  };

  const getAds = async () => {
    const options = {
      method: "GET",
      url: `ads`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        SetAdsData(response);
        SetAdsFilteredData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArticles = async () => {
    const options = {
      method: "GET",
      url: `blogArticles/GetArticles`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        SetArticlesData(response);
        SetArticleFilteredsData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBroadcasts = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `broadcasts`;

    await client
      .request(options)
      .then((response) => {
        SetBroadcastData(response);
        SetBroadcastFilteredData(response);
        if (response.length > 0) SetSelectedBroadcast(response[0].bro_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getActionsDetails = () => {
    switch (selectedTriggerAction) {
      case 0:
        return selectedSeries.toString();
      case 1:
        return selectedSeries.toString();
      case 2:
        return selectedSeries.toString();
      case 3:
        return JSON.stringify({
          tri_targetURL: triggerTargetURL,
          tri_body: triggerBody,
          tri_method: selectedURLMethod,
        });
      case 4:
        return selectedBroadcast.toString();
    }
  };

  const getEventDetails = () => {
    switch (selectedTriggerEvent) {
      case 0:
        return selectedItem.toString();
      default:
        return "";
    }
  };

  const addTriggerHandler = async () => {
    setIsSaving(true);
    if (updateTrigger) {
      options.method = "PUT";
      options.url = `settings/editTrigger`;
      options.data = JSON.stringify({
        tre_id: selectedTrigger.tre_id,
        tre_user_id: selectedTrigger.tre_user_id,
        tre_ser_id: 0,
        tre_emc_id: 0,
        tre_bro_id: 0,
        tre_segments: "",
        tre_event: selectedTriggerEvent,
        tre_eventDetails: getEventDetails(),
        tre_action: selectedTriggerAction,
        tre_actionDetails: getActionsDetails(),
        tre_status: selectedTrigger.tre_status,
      });
    } else {
      options.method = "POST";
      options.url = `settings/AddTrigger`;
      options.data = JSON.stringify({
        tre_id: 0,
        tre_user_id: 0,
        tre_ser_id: 0,
        tre_emc_id: 0,
        tre_bro_id: 0,
        tre_segments: "",
        tre_event: selectedTriggerEvent,
        tre_eventDetails: getEventDetails(),
        tre_action: selectedTriggerAction,
        tre_actionDetails: getActionsDetails(),
        tre_status: 0,
      });
    }

    await client
      .request(options)
      .then(() => {
        setSelectedTrigger({});
        getTriggers();
        setIsSaving(false);
        setUpdateTrigger(false);
        setAddTrigger(false);
        setSelectedTriggerEvent(0);

        setSelectedTriggerAction(0);
        setSelectedURLMethod(0);
        setSelectedSerie(0);
        setTriggerBody("");
        setTriggerTargetURL("");
      })
      .catch((error) => {
        setIsSaving(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const handleRenewApi = async () => {
    setRenewApi(true);
    options.method = "GET";
    options.url = `settings/renewAPIKey`;
    await client
      .request(options)
      .then((response) => {
        setRenewApi(false);
        setApiKey(response);
      })
      .catch((error) => {
        setRenewApi(false);
        console.log(error);
      });
  };

  const getSeries = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setSeries(response);
        if (response.length > 0) setSelectedSerie(response[0].ser_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getItems();
  }, [selectedCatalog]);

  useEffect(() => {
    getData();
    getTriggers();
    getSeries();
    getCatalogs();
    getBroadcasts();
  }, []);

  const handleChangeTriggerEvent = (event) => {
    setSelectedTriggerEvent(event.target.value);
  };
  const handleChangeTriggerAction = (event) => {
    setSelectedTriggerAction(event.target.value);
  };
  const handleChangeSeries = (event) => {
    setSelectedSerie(event.target.value);
  };
  const onChangeTriggerBody = (event) => {
    setTriggerBody(event.target.value);
  };
  const onChangeTriggerTargetURL = (event) => {
    setTriggerTargetURL(event.target.value);
  };
  const onChangeSegment = (value) => {
    setSegments(value);
  };

  const onChangeSegmentTrigger = (value) => {
    setSegmentsTrigger(value);
  };

  const onChangeCatalog = (event) => {
    SetSelectedCatalog(event.target.value);
  };
  const handleItemsClick = (event, id, name) => {
    SetSelectedItem(id);
    SetSelectedItemName(name);
  };

  const getEvent = (value) => {
    switch (value.tre_event) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              CLICKS ON THE FOLLOWING ITEM(s)
            </MDTypography>
            <br></br>
            <MDTypography variant="body">
              {value.tre_eventDetails == 0
                ? "Any "
                : ItemsFilteredData.filter((item) => item.item_id == value.tre_eventDetails)[0]
                    .item_name}
            </MDTypography>
          </div>
        );
      case 1:
        return (
          <MDTypography variant="body" fontWeight="medium">
            OPENS
          </MDTypography>
        );
      case 2:
        return (
          <MDTypography variant="body" fontWeight="medium">
            UNSUBSCRIBES
          </MDTypography>
        );
      case 3:
        return (
          <MDTypography variant="body" fontWeight="medium">
            BOUNCES
          </MDTypography>
        );
      case 4:
        return (
          <MDTypography variant="body" fontWeight="medium">
            COMPLAINTS
          </MDTypography>
        );
      case 30:
        return (
          <MDTypography variant="body" fontWeight="medium" color="info">
            REACHES THE NO ENGAGEMENT LIMIT
          </MDTypography>
        );
      case 40:
        return (
          <MDTypography variant="body" fontWeight="medium" color="info">
            FINISHES THIS SERIE
          </MDTypography>
        );
    }
  };

  const getAction = (value) => {
    switch (value) {
      case 0:
        return <MDTypography variant="body">COPY TO SERIE</MDTypography>;
      case 1:
        return <MDTypography variant="body">MOVE TO SERIE</MDTypography>;
      case 2:
        return <MDTypography variant="body">REMOVE FROM SERIE</MDTypography>;
      case 3:
        return <MDTypography variant="body">CALL URL</MDTypography>;
      case 4:
        return <MDTypography variant="body">SEND BROADCAST</MDTypography>;
    }
  };

  const getActionDetails = (tri) => {
    switch (tri.tre_action) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              COPY TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "All series"}
            </MDTypography>
          </div>
        );
      case 1:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              MOVE TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "All series"}
            </MDTypography>
          </div>
        );
      case 2:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              REMOVE FROM SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "All series"}
            </MDTypography>
          </div>
        );
      case 3:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              URL:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {JSON.parse(tri.tre_actionDetails).tri_targetURL}{" "}
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              METHOD:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {
                triggerMethod.filter(
                  (met) => met.value == JSON.parse(tri.tre_actionDetails).tri_method
                )[0].method
              }
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              BODY:{" "}
            </MDTypography>
            <MDTypography variant="body">{JSON.parse(tri.tre_actionDetails).tri_body}</MDTypography>
          </div>
        );
      case 4:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              SEND BROADCAST:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {" "}
              {broadcastData.filter((bro) => bro.bro_id == tri.tre_actionDetails)[0].bro_name}{" "}
            </MDTypography>
          </div>
        );
    }
  };

  const renderMenuSeries = (item) => {
    switch (item.value) {
      case 0:
        if (series.length > 0)
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.action}
            </MenuItem>
          );
        break;
      case 1:
        if (series.length > 0)
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.action}
            </MenuItem>
          );
        break;
      default:
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.action}
          </MenuItem>
        );
    }
  };

  const columnsTriggers = [
    {
      label: "Event",
      name: "tre_event",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getEvent(triggersData[dataIndex]),
      },
    },
    {
      label: "Action",
      name: "tre_action",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => getAction(value),
      },
    },
    {
      label: "Details",
      name: "tre_actionDetails",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return getActionDetails(triggersData[dataIndex]);
        },
      },
    },
    {
      label: " ",
      name: "tre_id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedTrigger(triggersData[dataIndex]);
                  setAddTrigger(true);
                  setUpdateTrigger(true);
                  setSelectedTriggerEvent(triggersData[dataIndex].tre_event);
                  setSelectedTriggerAction(triggersData[dataIndex].tre_action);

                  switch (triggersData[dataIndex].tre_event) {
                    case 0:
                      setSelectArticle(triggersData[dataIndex].tre_eventDetails);
                      break;
                    case 20:
                      SetSelectAd(triggersData[dataIndex].tre_eventDetails);
                      break;
                  }

                  switch (triggersData[dataIndex].tre_action) {
                    case 0:
                      setSelectedSerie(triggersData[dataIndex].tre_actionDetails);
                      break;
                    case 1:
                      setSelectedSerie(triggersData[dataIndex].tre_actionDetails);
                      break;
                    case 2:
                      setSelectedSerie(triggersData[dataIndex].tre_actionDetails);
                      break;
                    case 3:
                      setSelectedURLMethod(
                        JSON.parse(triggersData[dataIndex].tre_actionDetails).tri_method
                      );
                      setTriggerBody(
                        JSON.parse(triggersData[dataIndex].tre_actionDetails).tri_body
                      );
                      setTriggerTargetURL(
                        JSON.parse(triggersData[dataIndex].tre_actionDetails).tri_targetURL
                      );
                      break;
                    case 4:
                      setSelectBroadcast(triggersData[dataIndex].tre_actionDetails);
                      break;
                  }
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbarTrigger = () => <CustomToolbarTrigger onDelete={handleDeleteTriggers} />;

  const tableTriggersOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any global trigger",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "trigger(s) selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      triggersSelected = [];
      allRowsSelected.map((row) => {
        triggersSelected.push(triggersData[row.dataIndex]);
      });
    },
    customToolbarSelect: customToolbarTrigger,
  };

  const renderAlert = (
    <MDSnackbar
      color="success"
      icon="success"
      title="Settings saved"
      content=""
      dateTime=""
      open={openAlert}
      onClose={closeAlert}
      close={closeAlert}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3} pt={2} xs={12}>
        <Grid container spacing={6}>
          <Grid item>
            <MDBox
              mx={2}
              mt={1}
              py={3}
              px={2}
              bgColor="badgeColors.primary"
              borderRadius="xs"
              variant="gradient"
            >
              <MDTypography variant="h2">
                Settings
                {isLoading && (
                  <CircularProgress
                    size={30}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                )}
              </MDTypography>
            </MDBox>

            <MDBox pb={3} pt={2} xs={12}>
              <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                  <StatusPanel />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card m={5}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="primary"
                      borderRadius="xs"
                      color="white"
                      coloredShadow="primary"
                    >
                      <Typography>API key</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDTypography variant="body" fontWeight="medium">
                        Your API key :{" "}
                      </MDTypography>
                      <Typography>{apiKey}</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDButton
                        onClick={handleRenewApi}
                        disabled={renewApi}
                        color="success"
                        variant="gradient"
                      >
                        Renew
                      </MDButton>
                      {renewApi && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card m={5}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="primary"
                      borderRadius="xs"
                      color="white"
                      coloredShadow="primary"
                    >
                      <Typography>Timezone</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <TimezoneSelect
                        value={selectedTimezone}
                        onChange={(e) => setSelectedTimezone(e.value)}
                      />
                    </MDBox>

                    <MDBox m={2}>
                      <MDButton
                        onClick={handleSaveWordpress}
                        disabled={isSavingWordpress}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                      {isSavingWordpress && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card style={{ height: "700px" }}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="primary"
                      borderRadius="xs"
                      color="white"
                      coloredShadow="primary"
                    >
                      <Typography>Email sending</Typography>
                    </MDBox>

                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Unsubscribe URL"
                        fullWidth
                        value={unsubscribeUrl}
                        onChange={unsubscribeUrlChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "unsubscribeUrl",
                          unsubscribeUrl,
                          "required|url"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <TextField
                        label="Unsubscribe HTML paragraph"
                        fullWidth
                        multiline
                        value={unsubscribeHTML}
                        onChange={onChangeUnsubscribeHTML}
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "unsubscribeHTML",
                          unsubscribeHTML,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="From domain"
                        fullWidth
                        value={fromDomain}
                        onChange={fromDomainChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("fromDomain", fromDomain, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="Total daily sends"
                        fullWidth
                        value={totalDailySends}
                        onChange={totalDailySendsChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "totalDailySends",
                          totalDailySends,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="No Engagement limit sends"
                        fullWidth
                        value={noEngagementLimitSends}
                        onChange={(event) => setNoEngagementLimitSends(event.target.value)}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "noEngagementLimitSends",
                          noEngagementLimitSends,
                          "required|numeric"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="Initial send hour of day"
                        min={1}
                        max={24}
                        value={initialHourOfDay}
                        onChange={(event) => setInitialHourOfDay(event.target.value)}
                        required
                      />
                    </MDBox>
                    <MDBox m={2}>
                      <MDButton
                        onClick={handleSaveWordpress}
                        disabled={isSaving}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                      {isSaving && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card style={{ height: "700px" }}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="primary"
                      borderRadius="xs"
                      color="white"
                      coloredShadow="primary"
                    >
                      <Typography>Company</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Name"
                        fullWidth
                        value={companyName}
                        onChange={companyNameChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyName", companyName, "required")}
                      </MDBox>
                    </MDBox>

                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company State"
                        fullWidth
                        value={companyState}
                        onChange={companyStateChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyState", companyState, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Country"
                        fullWidth
                        value={companyCountry}
                        onChange={companyCountryChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "companyCountry",
                          companyCountry,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company City"
                        fullWidth
                        value={companyCity}
                        onChange={companyCityChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyCity", companyCity, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Address"
                        fullWidth
                        value={companyAddress}
                        onChange={companyAddressChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "companyAddress",
                          companyAddress,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Contact Us URL"
                        fullWidth
                        value={contactUsURL}
                        onChange={contactUsURLChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "contactUsURL",
                          contactUsURL,
                          "required|url"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDButton
                        onClick={handleSaveWordpress}
                        disabled={isSaving}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                      {isSaving && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                {false && (
                  <Grid item xs={12} md={4}>
                    <Card style={{ height: "550px" }}>
                      <MDBox
                        mx={2}
                        mt={1}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="primary"
                        borderRadius="xs"
                        color="white"
                        coloredShadow="primary"
                      >
                        <Typography>External email platforms</Typography>
                      </MDBox>

                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="Mautic URL"
                          fullWidth
                          value={mauticURL}
                          onChange={onChangeMauticURL}
                        />
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="Mautic Username"
                          fullWidth
                          value={mauticUserName}
                          onChange={mauticUsernameChange}
                        />
                        <MDBox color="red">
                          {simpleValidator.current.message(
                            "mauticUserName",
                            mauticUserName,
                            "alpha"
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type={passMauticVisible ? "text" : "password"}
                          label="Mautic Password"
                          fullWidth
                          value={mauticPassword}
                          onChange={mauticPasswordChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setPassMauticVisible(!passMauticVisible)}
                                  color="secondary"
                                  aria-label="prompt"
                                >
                                  {!passMauticVisible ? (
                                    <Tooltip title="View password">
                                      <VisibilityIcon />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Hide password">
                                      <VisibilityOffIcon />
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <MDBox color="red">
                          {simpleValidator.current.message(
                            "mauticPassword",
                            mauticPassword,
                            "alpha_num_dash"
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="Active campaigns URL"
                          fullWidth
                          value={activeCampaignURL}
                          onChange={onChangeActiveCampaignURL}
                        />
                        <MDBox color="red">
                          {simpleValidator.current.message(
                            "activeCampaignURL",
                            activeCampaignURL,
                            "url"
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="Active campaigns Api Key"
                          fullWidth
                          value={activeCampaignApiKey}
                          onChange={onChangeActiveCampaignApiKey}
                        />
                        <MDBox color="red">
                          {simpleValidator.current.message(
                            "activeCampaignApiKey",
                            activeCampaignApiKey,
                            "alpha_num_dash"
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox m={2}>
                        <MDButton
                          onClick={handleSaveWordpress}
                          disabled={isSaving}
                          color="success"
                          variant="gradient"
                        >
                          Save
                        </MDButton>
                        {isSaving && (
                          <CircularProgress
                            size={24}
                            style={{ marginLeft: 15, position: "relative", top: 4 }}
                          />
                        )}
                      </MDBox>
                    </Card>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="primary"
                      borderRadius="xs"
                      color="white"
                      coloredShadow="primary"
                    >
                      <Typography>Global triggers</Typography>
                    </MDBox>
                    <Grid container spacing={1} m={2}>
                      <Grid item xs={12}>
                        <MDButton
                          m={2}
                          mt={2}
                          color="secondary"
                          onClick={() => {
                            setAddTrigger(!addTrigger);
                          }}
                        >
                          Create a new trigger
                        </MDButton>
                      </Grid>
                      {addTrigger && (
                        <Grid
                          container
                          spacing={1}
                          m={2}
                          sx={{
                            //You can copy the code below in your theme
                            background: "#FFFFFF",
                            "& .MuiPaper-root": {
                              background: "#FFFFFF",
                            },
                            "& .MuiBackdrop-root": {
                              backgroundColor: "transparent", // Try to remove this to see the result
                            },
                          }}
                        >
                          <Grid item xs={12}>
                            <MDBox pb={2} display="flex" justifyContent="left">
                              <MDTypography variant="h6" fontWeight="medium" mt={1} ml={1} mr={2}>
                                When any subscriber
                              </MDTypography>
                              <Select
                                disabled={isLoading}
                                onChange={handleChangeTriggerEvent}
                                value={selectedTriggerEvent}
                                inputProps={{
                                  name: "select-trigger",
                                  id: "select-trigger",
                                }}
                              >
                                {triggerEvent.map((item) => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.event}
                                  </MenuItem>
                                ))}
                              </Select>
                              {selectedTriggerEvent == 0 && (
                                <>
                                  <MDTypography
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    ml={1}
                                    mr={2}
                                    mt={1}
                                  >
                                    in catalog
                                  </MDTypography>
                                  <Select
                                    disabled={isLoading}
                                    onChange={(e) => onChangeCatalog(e)}
                                    value={selectedCatalog}
                                  >
                                    {CatalogsData.map((cat) => (
                                      <MenuItem key={cat.cat_id} value={cat.cat_id}>
                                        {cat.cat_name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <MDTypography
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    ml={1}
                                    mr={2}
                                    mt={1}
                                  >
                                    "{selectedItemName}"
                                  </MDTypography>

                                  <MDButton
                                    onClick={() => setSelectItem(!selectItem)}
                                    variant="gradient"
                                    color="info"
                                  >
                                    Select item{" "}
                                    {selectItem ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </MDButton>
                                </>
                              )}
                            </MDBox>
                            {selectedTriggerEvent == 0 && selectItem && (
                              <Card
                                style={{
                                  height: "300px",
                                  marginBottom: 20,
                                  marginLeft: 10,
                                  marginRight: 10,
                                  border: "solid",
                                }}
                              >
                                <CardHeader
                                  title={
                                    <div>
                                      <MDTypography variant="body">Items</MDTypography>
                                      <MDInput
                                        onChange={(event) => handleFilterItem(event)}
                                        label="Filter"
                                        style={{ width: "95%", margin: 5 }}
                                      />
                                    </div>
                                  }
                                  style={{ color: "white", backgroundColor: "white" }}
                                />

                                <List style={{ overflow: "auto" }}>
                                  <ListItemButton
                                    sx={{ width: "100%" }}
                                    key={0}
                                    onClick={(event) => handleItemsClick(event, 0, "Any")}
                                  >
                                    <ListItemText>
                                      <span style={{ fontSize: "0.9rem" }}>{"Any"}</span>
                                    </ListItemText>
                                  </ListItemButton>
                                  <Divider variant="inset" component="li" />
                                  {ItemsFilteredData.map((item, index) => (
                                    <>
                                      <ListItemButton
                                        sx={{ width: "100%" }}
                                        key={item.item_id}
                                        onClick={(event) =>
                                          handleItemsClick(event, item.item_id, item.item_name)
                                        }
                                      >
                                        <ListItemText>
                                          <span style={{ fontSize: "0.9rem" }}>
                                            {item.item_name}
                                          </span>
                                        </ListItemText>
                                      </ListItemButton>
                                      <Divider variant="inset" component="li" />
                                    </>
                                  ))}
                                </List>
                              </Card>
                            )}

                            <MDBox pb={2} display="flex" justifyContent="left">
                              <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                                Perform the following action
                              </MDTypography>
                              <Select
                                disabled={isLoading}
                                onChange={handleChangeTriggerAction}
                                value={selectedTriggerAction}
                                inputProps={{
                                  id: "selectAction",
                                }}
                              >
                                {triggerActions.map((item) => renderMenuSeries(item))}
                              </Select>
                              {(selectedTriggerAction == 0 || selectedTriggerAction == 1) && (
                                <Select
                                  disabled={isLoading}
                                  onChange={handleChangeSeries}
                                  value={selectedSeries}
                                  inputProps={{
                                    id: "select-series",
                                  }}
                                >
                                  {series.map((item) => (
                                    <MenuItem key={item.ser_id} value={item.ser_id}>
                                      {item.ser_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                              {selectedTriggerAction == 2 && (
                                <Select
                                  disabled={isLoading}
                                  onChange={handleChangeSeries}
                                  value={selectedSeries}
                                  inputProps={{
                                    id: "select-series",
                                  }}
                                >
                                  <MenuItem key={-1} value={-1}>
                                    all series
                                  </MenuItem>
                                  {series.map((item) => (
                                    <MenuItem key={item.ser_id} value={item.ser_id}>
                                      {item.ser_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                              {selectedTriggerAction == 4 && (
                                <Select
                                  disabled={isLoading}
                                  onChange={(event) => SetSelectedBroadcast(event.target.value)}
                                  value={selectedBroadcast}
                                  inputProps={{
                                    id: "select-broadcasts",
                                  }}
                                >
                                  {broadcastData.map((item) => (
                                    <MenuItem key={item.bro_id} value={item.bro_id}>
                                      {item.bro_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </MDBox>
                            {selectedTriggerAction == 3 && (
                              <>
                                <MDBox pb={2} display="flex" justifyContent="left">
                                  <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                                    Method
                                  </MDTypography>
                                  <Select
                                    onChange={(event) => setSelectedURLMethod(event.target.value)}
                                    value={selectedURLMethod}
                                  >
                                    {triggerMethod.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.method}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </MDBox>
                                <MDBox m={2}>
                                  <MDInput
                                    type="text"
                                    label="Target URL"
                                    fullWidth
                                    value={triggerTargetURL}
                                    onChange={onChangeTriggerTargetURL}
                                    required
                                  />
                                </MDBox>
                                <MDBox m={2}>
                                  <MDInput
                                    type="text"
                                    label="JSON body"
                                    fullWidth
                                    value={triggerBody}
                                    onChange={onChangeTriggerBody}
                                    required
                                  />
                                </MDBox>
                              </>
                            )}
                            <MDBox pb={2} display="flex" justifyContent="left" spacing={5}>
                              <MDButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  setSelectedTrigger({});
                                  getTriggers();
                                  setIsSaving(false);
                                  setUpdateTrigger(false);
                                  setAddTrigger(false);
                                  setSelectedTriggerEvent(0);
                                  setSelectArticle(
                                    ArticlesData.length > 0 ? ArticlesData[0].bla_id : 0
                                  );
                                  setSelectArticle(false);
                                  setSelectedTriggerAction(0);
                                  setSelectedURLMethod(0);
                                  setSelectedSerie(0);
                                  setTriggerBody("");
                                  setTriggerTargetURL("");
                                }}
                              >
                                Close
                              </MDButton>
                              <MDButton
                                variant="gradient"
                                color="success"
                                onClick={() => addTriggerHandler()}
                              >
                                Save
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={11}>
                        <MUIDataTable
                          data={triggersData}
                          columns={columnsTriggers}
                          options={tableTriggersOptions}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {false && (
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={1}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="secondary"
                        borderRadius="xs"
                        color="white"
                        coloredShadow="secondary"
                      >
                        <Typography>HTML blocks</Typography>
                      </MDBox>
                      <HtmlBlocks />
                    </Card>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderErrorSB}
      {renderAlert}
    </DashboardLayout>
  );
}

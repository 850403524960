import { useEffect, useRef, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  TextField,
  Chip,
  CardHeader,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ReactTags from "react-tag-autocomplete";
import MDButton from "components/MDButton";
import {
  Checkbox,
  Switch,
  MenuItem,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  ListItemText,
} from "@material-ui/core";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useMaterialUIController } from "context";
import MSCustomFields from "components/MSCustomFields";

/* eslint-disable */
function Add(props) {
  const [errorSB, setErrorSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expandSettings, setExpandSettings] = useState(false);
  const [expandDisplay, setExpandDisplay] = useState(true);
  const [senderProfile, setSenderProfile] = useState(-1);
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]); // [senderProfile1, senderProfile2, senderProfile3
  const [form_name, setForm_name] = useState("");
  const [form_tags, setForm_tags] = useState([]);
  const [form_thankyouURL, setForm_thankyouURL] = useState("");
  const [form_alreadySubscribedURL, setForm_alreadySubscribedURL] = useState("");
  const [form_header, setForm_header] = useState("Get Free Email Update");
  const [form_description, setForm_description] = useState(
    "Join us for FREE to get instant email update"
  );
  const [form_label_name, setForm_label_name] = useState("Name");
  const [form_label_email, setForm_label_email] = useState("Email");
  const [form_label_phone, setForm_label_phone] = useState("Phone");
  const [form_field_name, setForm_field_name] = useState(true);
  const [form_field_phone, setForm_field_phone] = useState(true);
  const [form_required_name, setForm_required_name] = useState(false);
  const [form_required_phone, setForm_required_phone] = useState(false);
  const [form_newTab, setForm_newTab] = useState(true);
  const [form_doubleOptin, setForm_doubleOptin] = useState(false);
  const [form_buttonLabel, setForm_buttonLabel] = useState("Submit");
  const [form_buttonColor, setForm_buttonColor] = useState("#735AC7");
  const [form_backgroundColor, setForm_backgroundColor] = useState("#FFFFFF");
  const [form_emailText, setForm_emailText] = useState(
    "We're happy you subscribed to our newsletter. To start receiving our content, please confirm your email address by clicking the link below."
  );
  const [form_emailSubject, setForm_emailSubject] = useState("Subscription confirmation");
  const [form_emailButtonText, setForm_emailButtonText] = useState("Confirm");
  const [form_emailLogoURL, setForm_emailLogoURL] = useState("");
  const [form_emailTitle, setForm_emailTitle] = useState("Verify your email address to subscribe");
  const [form_confirmEmailUrl, setForm_confirmEmailUrl] = useState("");
  const [selectedConfirmUrl, setSelectedConfirmUrl] = useState(0);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [emailPreview, setEmailPreview] = useState("");
  const [customFields, setCustomFields] = useState([]);

  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorBackgroundPicker, setDisplayColorBackgroundPicker] = useState(false);

  const [selectedThankYou, setSelectedThankYou] = useState(0);
  const [selectedAlreadySubscribed, setSelectedAlreadySubscribed] = useState(0);

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const accordionRef = useRef();
  const filter = createFilterOptions();

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `${form_buttonColor}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const stylesBackground = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `${form_backgroundColor}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            if (!form_doubleOptin) return true;
            else return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setForm_buttonColor(color.hex);
  };

  const handleCloseBackground = () => {
    setDisplayColorBackgroundPicker(false);
  };

  const handleClickBackground = () => {
    setDisplayColorBackgroundPicker(!displayColorBackgroundPicker);
  };

  const handleChangeBackground = (c) => {
    setForm_backgroundColor(c.hex);
  };

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setForm_name("");
    setForm_tags([]);
    setForm_thankyouURL("");
    setForm_alreadySubscribedURL("");
    setForm_header("Get Free Email Update");
    setForm_description("Join us for FREE to get instant email update");
    setForm_label_name("Name");
    setForm_label_email("Email");
    setForm_label_phone("Phone");
    setForm_field_name(true);
    setForm_field_phone(true);
    setForm_required_name(false);
    setForm_required_phone(false);
    setForm_newTab(true);
    setForm_buttonLabel("Submit");
    setForm_buttonColor("#735AC7");
    setForm_backgroundColor("#FFFFFF");
    setHtmlPreview("");
    setSelectedThankYou(0);
    setSelectedAlreadySubscribed(0);
    setForm_doubleOptin(false);
    setForm_emailTitle("");
    setForm_emailSubject("");
    setForm_emailText("");
    setForm_emailButtonText("");
    setForm_emailLogoURL("");
    setCustomFields([]);
    setSenderProfile(-1);

    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleRefreshPreview = () => {
    const options = {
      method: "POST",
      url: `forms/preview`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      form_sep_id: senderProfile,
      form_name: form_name,
      form_tags: form_tags.join(","),
      form_thankyouURL: form_thankyouURL,
      form_alreadySubscribedURL: form_alreadySubscribedURL,
      form_header: form_header,
      form_description: form_description,
      form_label_name: form_label_name,
      form_label_email: form_label_email,
      form_label_phone: form_label_phone,
      form_field_name: form_field_name,
      form_field_phone: form_field_phone,
      form_required_name: form_required_name,
      form_required_phone: form_required_phone,
      form_buttonLabel: form_buttonLabel,
      form_buttonColor: form_buttonColor,
      form_backgroundColor: form_backgroundColor,
      form_newTab: form_newTab,
      form_doubleOptin: form_doubleOptin,
      form_emailTitle: form_emailTitle,
      form_emailSubject: form_emailSubject,
      form_emailText: form_emailText,
      form_emailButtonText: form_emailButtonText,
      form_emailLogoUrl: form_emailLogoURL,
      form_confirmEmailUrl: selectedConfirmUrl === 0 ? form_confirmEmailUrl : "",
      form_customFields: JSON.stringify(customFields),
    });

    client
      .request(options)
      .then((response) => {
        setHtmlPreview(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleEmailPreview = () => {
    const options = {
      method: "POST",
      url: `forms/emailPreview`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      form_sep_id: senderProfile,
      form_name: form_name,
      form_tags: form_tags.join(","),
      form_thankyouURL: form_thankyouURL,
      form_alreadySubscribedURL: form_alreadySubscribedURL,
      form_header: form_header,
      form_description: form_description,
      form_label_name: form_label_name,
      form_label_email: form_label_email,
      form_label_phone: form_label_phone,
      form_field_name: form_field_name,
      form_field_phone: form_field_phone,
      form_required_name: form_required_name,
      form_required_phone: form_required_phone,
      form_buttonLabel: form_buttonLabel,
      form_buttonColor: form_buttonColor,
      form_backgroundColor: form_backgroundColor,
      form_newTab: form_newTab,
      form_doubleOptin: form_doubleOptin,
      form_emailTitle: form_emailTitle,
      form_emailSubject: form_emailSubject,
      form_emailText: form_emailText,
      form_emailButtonText: form_emailButtonText,
      form_emailLogoUrl: form_emailLogoURL,
      form_confirmEmailUrl: selectedConfirmUrl === 0 ? form_confirmEmailUrl : "",
      form_customFields: JSON.stringify(customFields),
    });

    client
      .request(options)
      .then((response) => {
        setEmailPreview(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `forms`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        form_sep_id: senderProfile,
        form_name: form_name,
        form_tags: form_tags.join(","),
        form_thankyouURL: form_thankyouURL,
        form_alreadySubscribedURL: form_alreadySubscribedURL,
        form_header: form_header,
        form_description: form_description,
        form_label_name: form_label_name,
        form_label_email: form_label_email,
        form_label_phone: form_label_phone,
        form_field_name: form_field_name,
        form_field_phone: form_field_phone,
        form_required_name: form_required_name,
        form_required_phone: form_required_phone,
        form_buttonLabel: form_buttonLabel,
        form_buttonColor: form_buttonColor,
        form_backgroundColor: form_backgroundColor,
        form_newTab: form_newTab,
        form_doubleOptin: form_doubleOptin,
        form_emailTitle: form_emailTitle,
        form_emailSubject: form_emailSubject,
        form_emailText: form_emailText,
        form_emailButtonText: form_emailButtonText,
        form_emailLogoUrl: form_emailLogoURL,
        form_confirmEmailUrl: selectedConfirmUrl === 0 ? form_confirmEmailUrl : "",
        form_customFields: JSON.stringify(customFields),
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      if (form_tags.length == 0) {
        setExpandSettings(true);
        if (accordionRef.current) {
          accordionRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSenderProfiles = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        setArrSenderProfiles(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPreviousUrls = async () => {
    const options = {
      method: "GET",
      url: `forms/PreviousUrls`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setForm_thankyouURL(response.thankyou);
        setForm_alreadySubscribedURL(response.alreadySubscribed);
        if (response.thankyou !== "") setSelectedThankYou(1);
        if (response.alreadySubscribed !== "") setSelectedAlreadySubscribed(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTags();
    getSenderProfiles();
    handleRefreshPreview();
    getPreviousUrls();
  }, []);

  useEffect(() => {
    handleRefreshPreview();
  }, [
    form_backgroundColor,
    form_buttonColor,
    form_header,
    form_description,
    form_field_phone,
    form_field_name,
    form_label_email,
    form_label_name,
    form_label_phone,
    form_required_name,
    form_required_phone,
    form_buttonLabel,
  ]);

  useEffect(() => {
    if (form_doubleOptin) handleEmailPreview();
  }, [
    form_emailTitle,
    form_emailSubject,
    form_emailText,
    form_emailButtonText,
    form_emailLogoURL,
    form_doubleOptin,
  ]);

  const handleChangeCustomFields = (fields) => {
    setCustomFields(fields);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Create a new form
        </MDTypography>
      </MDBox>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Accordion expanded={expandDisplay}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ color: "white", backgroundColor: "white" }}
              >
                <MDTypography>Display settings</MDTypography>
              </AccordionSummary>
              <AccordionDetails style={{ color: "white", backgroundColor: "white" }}>
                <MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      required
                      onChange={(event) => setForm_name(event.target.value)}
                      label="Form name"
                      value={form_name}
                      style={{ width: "95%", margin: 5 }}
                    />
                    <MDBox color="red">
                      {simpleValidator.current.message("Form name", form_name, "required")}
                    </MDBox>
                  </MDBox>

                  <MDBox mb={1}>
                    <MDInput
                      onChange={(event) => setForm_header(event.target.value)}
                      label="Header"
                      value={form_header}
                      style={{ width: "95%", margin: 5 }}
                    />
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      onChange={(event) => setForm_description(event.target.value)}
                      label="Description"
                      multiline
                      value={form_description}
                      style={{ width: "95%", margin: 5 }}
                    />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium" ml={2}>
                      Email label
                    </MDTypography>
                    <MDInput
                      label="Email label"
                      style={{ width: "95%", margin: 5 }}
                      value={form_label_email}
                      onChange={(event) => setForm_label_email(event.target.value)}
                    />
                  </MDBox>

                  <MDBox display="flex">
                    <Switch
                      checked={form_field_name}
                      onChange={() => setForm_field_name(!form_field_name)}
                    />
                    <MDTypography variant="h6" fontWeight="medium" mt={1} mr={2}>
                      Name label
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      disabled={!form_field_name}
                      label="Name label"
                      style={{ width: "95%", margin: 5 }}
                      value={form_label_name}
                      onChange={(event) => setForm_label_name(event.target.value)}
                    />
                    <MDBox display="flex">
                      <Checkbox
                        disabled={!form_field_name}
                        checked={form_required_name}
                        onChange={() => setForm_required_name(!form_required_name)}
                      />
                      <MDTypography variant="body" mt={1} mr={2}>
                        Required
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex">
                    <Switch
                      checked={form_field_phone}
                      onChange={() => {
                        setForm_field_phone(!form_field_phone);
                      }}
                    />
                    <MDTypography variant="h6" fontWeight="medium" mt={1} mr={2}>
                      Phone label
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      disabled={!form_field_phone}
                      label="Phone label"
                      style={{ width: "95%", margin: 5 }}
                      value={form_label_phone}
                      onChange={(event) => setForm_label_phone(event.target.value)}
                    />
                    <MDBox display="flex">
                      <Checkbox
                        disabled={!form_field_phone}
                        checked={form_required_phone}
                        onChange={() => setForm_required_phone(!form_required_phone)}
                      />
                      <MDTypography variant="body" mt={1} mr={2}>
                        Required
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox mb={3}>
                    <MDTypography variant="h6" fontWeight="medium" ml={2} mb={2}>
                      Custom fields
                    </MDTypography>
                    <MSCustomFields
                      fields={customFields}
                      setFields={(value) => handleChangeCustomFields(value)}
                    />
                  </MDBox>
                  <MDBox display="flex">
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1} ml={2}>
                        Button label
                      </MDTypography>
                      <MDInput
                        label="Button label"
                        style={{ width: "95%", margin: 5 }}
                        value={form_buttonLabel}
                        onChange={(event) => setForm_buttonLabel(event.target.value)}
                      />
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="medium" mt={1} ml={2}>
                        Button color
                      </MDTypography>
                      <MDBox ml={4} mt={2}>
                        <div>
                          <div style={styles.swatch} onClick={handleClick}>
                            <div style={styles.color} />
                          </div>
                          {displayColorPicker ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={handleClose} />
                              <SketchPicker
                                color={form_buttonColor}
                                onChange={handleChange}
                                disableAlpha
                              />
                            </div>
                          ) : null}
                        </div>
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} ml={2}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1} ml={2}>
                        Background color
                      </MDTypography>
                      <MDBox ml={6} mt={2}>
                        <div>
                          <div style={stylesBackground.swatch} onClick={handleClickBackground}>
                            <div style={stylesBackground.color} />
                          </div>
                          {displayColorBackgroundPicker ? (
                            <div style={stylesBackground.popover}>
                              <div style={stylesBackground.cover} onClick={handleCloseBackground} />
                              <SketchPicker
                                color={form_backgroundColor}
                                onChange={handleChangeBackground}
                                disableAlpha
                              />
                            </div>
                          ) : null}
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandSettings}
              onChange={() => setExpandSettings(!expandSettings)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ color: "white", backgroundColor: "white" }}
              >
                {" "}
                <MDTypography>General settings</MDTypography>
              </AccordionSummary>
              <AccordionDetails style={{ color: "white", backgroundColor: "white" }}>
                <MDBox width="100%">
                  <div ref={accordionRef}>
                    <MDBox ml={2} mr={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="The keywords with which subscribers who opt-in through this form are tagged">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={form_tags}
                        onChange={(event, newValue) => {
                          setForm_tags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("Tags", form_tags, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} mr={2} pl={2}>
                      <Select
                        onChange={(e) => setSelectedThankYou(e.target.value)}
                        value={selectedThankYou}
                      >
                        <MenuItem key={0} value={0}>
                          Default thank you page
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Custom page URL
                        </MenuItem>
                      </Select>
                      {selectedThankYou === 1 && (
                        <MDInput
                          onChange={(event) => setForm_thankyouURL(event.target.value)}
                          label="Thank you URL"
                          value={form_thankyouURL}
                          style={{ width: "95%", margin: 5 }}
                        />
                      )}
                    </MDBox>
                    <MDBox mb={4} mr={2} pl={2}>
                      <Select
                        onChange={(e) => setSelectedAlreadySubscribed(e.target.value)}
                        value={selectedAlreadySubscribed}
                      >
                        <MenuItem key={0} value={0}>
                          Default already subscribed page
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Custom page URL
                        </MenuItem>
                      </Select>
                      {selectedAlreadySubscribed === 1 && (
                        <MDInput
                          onChange={(event) => setForm_alreadySubscribedURL(event.target.value)}
                          label="Custom already subscribed URL"
                          value={form_alreadySubscribedURL}
                          style={{ width: "95%", margin: 5 }}
                        />
                      )}
                    </MDBox>
                    <MDBox display="flex">
                      <Checkbox
                        checked={form_newTab}
                        onChange={() => setForm_newTab(!form_newTab)}
                      />
                      <MDTypography variant="body" mt={1} mr={2}>
                        Open in a new window
                      </MDTypography>
                    </MDBox>

                    <MDBox display="flex">
                      <Checkbox
                        disabled={arrSenderProfiles.length === 0}
                        checked={form_doubleOptin}
                        onChange={() => setForm_doubleOptin(!form_doubleOptin)}
                      />
                      <MDTypography variant="body" mt={1}>
                        Enable double opt-in
                      </MDTypography>

                      <Tooltip title="You must have at least one sender profile to be able to use double opt-in.">
                        <Icon style={{ marginTop: 8, marginLeft: 2 }}>info</Icon>
                      </Tooltip>
                    </MDBox>
                    {form_doubleOptin && (
                      <Card style={{ color: "text", backgroundColor: "white" }}>
                        <CardHeader
                          title={
                            <div>
                              <MDTypography>Opt-in form email settings</MDTypography>
                            </div>
                          }
                          style={{ color: "white", backgroundColor: "white" }}
                        />

                        <MDBox ml={2} mb={2}>
                          {arrSenderProfiles.length > 0 && (
                            <Select
                              placeholder="Select sender profile"
                              value={senderProfile}
                              onChange={(e) => {
                                setSenderProfile(e.target.value);
                              }}
                            >
                              <MenuItem key={-1} value={-1}>
                                <ListItemText primary={"Select a sender profile"} secondary="_" />
                              </MenuItem>
                              {arrSenderProfiles.map((item) => (
                                <MenuItem key={item.sep_id} value={item.sep_id}>
                                  <ListItemText
                                    primary={"From:" + item.sep_envelopFrom}
                                    secondary={"Reply to:" + item.sep_replyTo}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          <MDBox color="red">
                            {simpleValidator.current.message(
                              "Sender Profile",
                              senderProfile,
                              "sender"
                            )}
                          </MDBox>
                        </MDBox>
                        <MDBox mb={2} mr={2} pl={2}>
                          <Select
                            onChange={(e) => setSelectedConfirmUrl(e.target.value)}
                            value={selectedConfirmUrl}
                          >
                            <MenuItem key={0} value={0}>
                              Default confirm URL
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                              Custom confirm URL
                            </MenuItem>
                          </Select>
                          {selectedConfirmUrl === 1 && (
                            <MDInput
                              onChange={(event) => setForm_confirmEmailUrl(event.target.value)}
                              label="Confirm URL"
                              value={form_confirmEmailUrl}
                              style={{ width: "95%", margin: 5 }}
                            />
                          )}
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="medium" ml={2}>
                            Email title
                          </MDTypography>
                          <MDInput
                            label="Email title"
                            style={{ width: "95%", margin: 5 }}
                            value={form_emailTitle}
                            onChange={(event) => setForm_emailTitle(event.target.value)}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="medium" ml={2}>
                            Email subject
                          </MDTypography>
                          <MDInput
                            label="Email subject"
                            style={{ width: "95%", margin: 5 }}
                            value={form_emailSubject}
                            onChange={(event) => setForm_emailSubject(event.target.value)}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="medium" ml={2}>
                            Email text
                          </MDTypography>
                          <MDInput
                            multiline
                            label=" Email text body"
                            style={{ width: "95%", margin: 5 }}
                            value={form_emailText}
                            onChange={(event) => setForm_emailText(event.target.value)}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="medium" ml={2}>
                            Email button text
                          </MDTypography>
                          <MDInput
                            label="Email button text"
                            style={{ width: "95%", margin: 5 }}
                            value={form_emailButtonText}
                            onChange={(event) => setForm_emailButtonText(event.target.value)}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="medium" ml={2}>
                            Email logo URL
                          </MDTypography>
                          <MDInput
                            label="Email logo URL"
                            style={{ width: "95%", margin: 5 }}
                            value={form_emailLogoURL}
                            onChange={(event) => setForm_emailLogoURL(event.target.value)}
                          />
                        </MDBox>
                      </Card>
                    )}
                  </div>
                </MDBox>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={8}>
            <MDBox pt={4} pb={3} px={2}>
              {htmlPreview && (
                <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                  <Grid item mb={2}>
                    <iframe
                      srcDoc={htmlPreview}
                      height="600"
                      width="100%"
                      allowFullScreen
                      style={{ border: "none" }}
                    />
                  </Grid>
                </Card>
              )}
              {form_doubleOptin && emailPreview && (
                <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                  <Grid item mb={2}>
                    <iframe
                      srcDoc={emailPreview}
                      height="600"
                      width="100%"
                      allowFullScreen
                      style={{ border: "none" }}
                    />
                  </Grid>
                </Card>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Add;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MDSnackbar from "components/MDSnackbar";
import { TableCell, Chip, Icon } from "@material-ui/core";
// import Table from "@mui/material/Table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConfirmationAlert from "components/ConfirmationAlert";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Moment from "moment";
import client from "ApiClient";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Select,
} from "@mui/material";
// import PieChart from "examples/Charts/PieChart";
import { FeatureFlags } from "context/FeatureFlags";
import MDButton from "components/MDButton";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit copy";
import Details from "./details";
import GetSegmentation from "components/Segments/getSegmentation";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dnsFailure, setDnsFailure] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [broadcasts, setBroadcasts] = useState([]);
  const [broadcastsSelected, setBroadcastSelected] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [clone, setClone] = useState(false);
  const [percentage, setPercentage] = useState("true");
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSerie] = useState();
  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const handleOpenModal = () => setOpenModal(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [create, setCreate] = useState(true);

  const [openErrorDNS, setOpenErrorDNS] = useState(false);
  const closeErrorDNS = () => setOpenErrorDNS(false);

  const { features } = useContext(FeatureFlags);
  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const options = {
    method: "GET",
    url: "broadcasts",
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `broadcasts`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        if (response.filter((bro) => bro.bro_status == 16).length > 0) setDnsFailure(true);
        else setDnsFailure(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      setIsLoading(true);
      setAcconuntsData([]);
      setPreviewTemplate("");
      setSubjectPreview("");
      getData();
    }
  }, [openLog]);

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const verifyDNS = (bro) => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `broadcasts/verifyDns?bro_id=${bro.bro_id}`,
      headers: {
        "content-type": "application/json",
      },
    };

    // options.data = JSON.stringify({ bro_id: bro.bro_id });

    client
      .request(options)
      .then((response) => {
        console.log(response);
        if (response == "ok") {
          getData();
          setDnsFailure(false);
        } else {
          setOpenErrorDNS(true);
          setDnsFailure(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `broadcasts`;
    options.data = JSON.stringify(
      acconuntsData.filter((item, index) => {
        return broadcastsSelected.indexOf(index) >= 0 ? true : false;
      })
    );
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenAlert(false);
        setBroadcastSelected([]);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const copySerie = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `emailCopy/copyToSerie`;

    options.headers = {
      "content-type": "application/json",
    };

    options.data = JSON.stringify({
      emc_id: broadcasts.bro_emc_id,
      ser_id: selectedSeries,
    });

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenCopy(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const pause = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/pause`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const resume = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/resume`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const cancel = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/cancel`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSeries = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") {
          setSeries(response);
          if (response.length > 0) setSelectedSerie(response[0].ser_id);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        response.length > 0 ? setCreate(true) : setCreate(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("broadcast_percentage") == null)
      window.sessionStorage.setItem("broadcast_percentage", percentage);
    else setPercentage(window.sessionStorage.getItem("broadcast_percentage"));
    getSenderProfiles();
    getData();
    getSeries();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSelected) getData();
    }, 10000);
    return () => clearInterval(interval);
  }, [isSelected]);

  useEffect(() => {
    if (broadcastsSelected.length > 0) setIsSelected(true);
    else setIsSelected(false);
  }, [broadcastsSelected]);

  const handlePreview = (subject, body) => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/Preview`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: subject,
      body: body,
    });
    client
      .request(options)
      .then((response) => {
        setSubjectPreview(response.subject);
        setPreviewTemplate(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleChangeSeries = (event) => {
    setSelectedSerie(event.target.value);
  };

  const changePercentageHandler = (event) => {
    window.sessionStorage.setItem("broadcast_percentage", event.target.value);
    setPercentage(event.target.value);
  };

  const handleClickEdit = (e, index) => {
    setAnchorEl(null);
    alert(`Editar cuenta de ${index}`);
  };

  const getStatus = (bro) => {
    switch (bro.bro_status) {
      case 0:
        return <Chip style={{ backgroundColor: "#F7C66D" }} label="Pending" />;
      case 1:
        return <Chip style={{ backgroundColor: "#9285C7" }} label="Sending" />;
      case 2:
        return <Chip style={{ backgroundColor: "#bce2be" }} label="Sent" />;
      case 3:
        return <Chip style={{ backgroundColor: "#FF4D33" }} label="Failed" />;
      case 4:
        return <Chip style={{ backgroundColor: "#6DA3FA" }} label="Paused" />;
      case 5:
        return <Chip style={{ backgroundColor: "#FF5D59" }} label="Canceled" />;
      case 6:
        return <Chip style={{ backgroundColor: "#6DA3FA" }} label="Draft" />;
      case 11:
        return <Chip style={{ backgroundColor: "#9285C7" }} label="Creating" />;
      case 12:
        if (bro.bro_totalSent > 0)
          return <Chip style={{ backgroundColor: "#9285C7" }} label="Sending" />;
        else return <Chip style={{ backgroundColor: "#9285C7" }} label="Building" />;
      case 13:
        if (bro.bro_totalSent > 0)
          return <Chip style={{ backgroundColor: "#9285C7" }} label="Sending" />;
        else return <Chip style={{ backgroundColor: "#9285C7" }} label="Ready" />;
      case 14:
        return <Chip style={{ backgroundColor: "#FAF88A" }} label=" Approval pending" />;
      case 15:
        return (
          <Tooltip title={bro.bro_notes}>
            <Chip style={{ backgroundColor: "#FF4D33" }} label="Rejected" />
          </Tooltip>
        );
      case 16:
        return (
          <Tooltip title="We noticed that some of the sending domain DNS records for one of your configured domains are not resolving properly, go to your sender domains and make sure you have the domain fully verified.">
            <Chip style={{ backgroundColor: "#FF4D33", color: "#FFF" }} label="DNS failure" />
          </Tooltip>
        );
    }
  };

  const columns = [
    {
      label: "Status",
      name: "bro_status",
      options: {
        filter: true,
        filterType: "checkbox",
        filterOptions: {
          renderValue: (val) => {
            switch (val) {
              case 0:
                return "Pending";
              case 1:
                return "Sending";
              case 2:
                return "Sent";
              case 3:
                return "Failed";
              case 4:
                return "Paused";
              case 5:
                return "Canceled";
              case 6:
                return "Draft";
              case 11:
                return "Creating";
              /*
              case 12:
                if (bro.bro_totalSent > 0) return "Sending";
                else return "Building";
              case 13:
                if (bro.bro_totalSent > 0) return "Sending";
                else return "Ready";
                */
              case 14:
                return "Approval pending";
              case 15:
                return "Rejected";
              case 16:
                return "DNS failure";
            }
          },
        },
        customFilterListOptions: {
          render: (v) => {
            switch (v) {
              case 0:
                return "Pending";
              case 1:
                return "Sending";
              case 2:
                return "Sent";
              case 3:
                return "Failed";
              case 4:
                return "Paused";
              case 5:
                return "Canceled";
              case 6:
                return "Draft";
              case 11:
                return "Creating";
              /*
            case 12:
              if (bro.bro_totalSent > 0) return "Sending";
              else return "Building";
            case 13:
              if (bro.bro_totalSent > 0) return "Sending";
              else return "Ready";
              */
              case 14:
                return "Approval pending";
              case 15:
                return "Rejected";
              case 16:
                return "DNS failure";
            }
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        customBodyRenderLite: (dataIndex, rowIndex) => getStatus(acconuntsData[dataIndex]),
      },
    },
    {
      label: "Name",
      name: "bro_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Recipients",
      name: "total_recipients",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Sent",
      name: "bro_totalSent",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Sent %",
      name: "bro_totalSentPerc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Opens %",
      name: "total_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "CTR",
      name: "total_clicks_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "CTOR",
      name: "total_clicks_over_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => value + " %",
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubscribes",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Unsubscribes %",
      name: "total_unsubs_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Complaints %",
      name: "total_complaints_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Sales",
      name: "total_sales",
      options: {
        display: false,
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => "$ " + Math.round(value),
      },
    },
    {
      label: "Segmentation",
      name: "bro_segments",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRender: (value) => <GetSegmentation segment={value} />,
      },
    },
    {
      label: "Scheduled date",
      name: "bro_date",
      options: {
        filter: false,
        // customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "bro_id",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const approval = localStorage.getItem("requestApproval") == "true" ? true : false;
          return (
            <div>
              {!features.hiddenMenus.includes("series") && (
                <IconButton
                  onClick={() => {
                    setBroadcasts(acconuntsData[dataIndex]);
                    setOpenCopy(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Copy to serie">
                    <ReadMoreIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
              {(acconuntsData[dataIndex].bro_status == 16 ||
                acconuntsData[dataIndex].bro_status == 6 ||
                (acconuntsData[dataIndex].bro_status == 0 && approval == false) ||
                (acconuntsData[dataIndex].bro_status == 14 && approval == true)) && (
                <IconButton
                  onClick={() => {
                    setBroadcasts(acconuntsData[dataIndex]);
                    setOpenLog(true);
                    setClone(false);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Edit">
                    <EditIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
              {acconuntsData[dataIndex].bro_status != 6 && (
                <IconButton
                  onClick={() => {
                    setBroadcasts(acconuntsData[dataIndex]);
                    setOpenLog(true);
                    setClone(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Clone and edit">
                    <ContentCopyIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  setBroadcasts(acconuntsData[dataIndex]);
                  setOpenDetails(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="View details">
                  <VisibilityIcon fontSize="small" />
                </Tooltip>
              </IconButton>
              {acconuntsData[dataIndex].bro_status == 1 && (
                <IconButton
                  onClick={() => {
                    setBroadcasts(acconuntsData[dataIndex]);
                    pause();
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Pause">
                    <PauseIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
              {acconuntsData[dataIndex].bro_status == 4 && (
                <IconButton
                  onClick={() => {
                    setBroadcasts(acconuntsData[dataIndex]);
                    resume();
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Resume">
                    <PlayArrowIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
              {acconuntsData[dataIndex].bro_status != 2 &&
                acconuntsData[dataIndex].bro_status != 3 &&
                acconuntsData[dataIndex].bro_status != 5 &&
                acconuntsData[dataIndex].bro_status != 15 && (
                  <IconButton
                    onClick={() => {
                      setBroadcasts(acconuntsData[dataIndex]);
                      cancel();
                    }}
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                    aria-label="prompt"
                  >
                    <Tooltip id="button-report" title="Cancel">
                      <CancelIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                )}
              {acconuntsData[dataIndex].bro_status == 16 && (
                <IconButton
                  onClick={() => {
                    verifyDNS(acconuntsData[dataIndex]);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Verify DNS">
                    <Icon fontSize="small"> dns</Icon>
                  </Tooltip>
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const customToolbarSelect = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const CustomToolbar = ({ displayData }) => {
    return (
      <Tooltip title="Change the stats view between counts and percentage" placement="top">
        <Select
          style={{ marginLeft: 15 }}
          value={percentage}
          label="Change the view of opens and clicks"
          onChange={(event) => changePercentageHandler(event)}
        >
          <MenuItem value="false">#</MenuItem>
          <MenuItem value="true">%</MenuItem>
        </Select>
      </Tooltip>
    );
  };

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: false,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    searchPlaceholder: "Search",
    rowsSelected: broadcastsSelected,
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setBroadcastSelected(rowsSelected);
      /*
      setBroadcastSelected([]);
      allRowsSelected.map((row) => {
        setBroadcastSelected((prev) => [...prev, acconuntsData[row.dataIndex]]);
      });
      */
    },
    customToolbarSelect: customToolbarSelect,
    customToolbar: CustomToolbar,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this broadcast?"
      />
      <Edit
        openModal={openLog}
        closeModal={() => {
          getData();
          setOpenLog(false);
        }}
        broId={broadcasts.bro_id}
        series={series}
        segments={JSON.stringify(broadcasts.bro_segments)}
        triggers={broadcasts.trigger_events}
        clone={clone}
      />
      <Details
        openModal={openDetails}
        closeModal={() => {
          setOpenDetails(false);
        }}
        broId={broadcasts.bro_id}
        series={series}
        segments={JSON.stringify(broadcasts.bro_segments)}
        triggers={broadcasts.trigger_events}
        clone={false}
      />
      <Dialog open={openCopy} closeModal={() => setOpenCopy(false)}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="xs"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            {"Copy "} {broadcasts.bro_name}
          </MDTypography>
        </MDBox>
        <DialogContent>
          <MDTypography variant="h6" fontWeight="medium" mt={1}>
            Copy to serie
          </MDTypography>
          <Select
            label={"select any serie"}
            fullWidth
            onChange={handleChangeSeries}
            value={selectedSeries}
          >
            {series.map((item) => (
              <MenuItem key={item.ser_id} value={item.ser_id}>
                {item.ser_name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setOpenCopy(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          <MDButton onClick={copySerie} disabled={isLoading} variant="gradient" color="success">
            Save
          </MDButton>
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>
      <MDBox mx={2} mt={1} py={3} px={2} bgColor="white" borderRadius="xs">
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Broadcasts
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              {create && (
                <Tooltip title="Create a new broadcast">
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    onClick={props.onAdd}
                    style={{ marginLeft: "auto" }}
                  >
                    Create new
                  </MDButton>
                </Tooltip>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {dnsFailure && !isLoading && (
          <MDTypography variant="caption" color="error">
            We noticed that some of the sending domain DNS records for one of your configured
            domains are not resolving properly, go to your sender domains and make sure you have the
            domain fully verified.
          </MDTypography>
        )}
        {!create && !isLoading && (
          <MDTypography variant="caption" color="error">
            You need to create a sender profile in order to add a broadcast
          </MDTypography>
        )}
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
        {previewTemplate != "" && (
          <Card
            style={{ padding: 10, backgroundColor: "white", marginTop: 10, width: "100%" }}
            xs={12}
          >
            <Grid container mt={5}>
              <Grid item mb={2} xs={12}>
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Subject
                </MDTypography>
                <iframe
                  srcDoc={subjectPreview}
                  height="100"
                  width="100%"
                  allowFullScreen
                  style={{ border: "none" }}
                />
              </Grid>
              <Grid item mb={2} xs={12}>
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Body
                </MDTypography>
                <iframe
                  srcDoc={previewTemplate}
                  height="600"
                  width="100%"
                  allowFullScreen
                  style={{ border: "none" }}
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
      <MDSnackbar
        color="error"
        icon="error"
        title="DNS falied"
        content="The DNS are not resolving properly"
        dateTime={""}
        open={openErrorDNS}
        onClose={closeErrorDNS}
        close={closeErrorDNS}
        bgWhite
      />
    </div>
  );
}

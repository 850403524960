import { useContext, useEffect, useRef, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Select,
  Tooltip,
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment-timezone";
import MDButton from "components/MDButton";
import EmailEditor from "react-email-editor";
import MDSnackbar from "components/MDSnackbar";
import MSPersonalizationTags from "components/MSPersonalizationTags";
import { Checkbox, Icon } from "@material-ui/core";
import { FeatureFlags } from "context/FeatureFlags";
import MSMultipleSubjects from "components/MSMultipleSubjects";

/* eslint-disable */
function EmailCopy(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState(null);
  const [testEmail, setTestEmail] = useState("");
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]);
  const [senderProfile, setSenderProfile] = useState(-1);
  const [showTracking, setShowTracking] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [jsonDesign, setJsonDesign] = useState("");
  const [catalogs, setCatalogs] = useState([]);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const emailEditorRef = useRef(null);
  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);

  const [selectedSubject, setSelectedSubject] = useState("");

  const { features } = useContext(FeatureFlags);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );
  const clearVariables = () => {
    setSubject(null);
    setSenderProfile(-1);
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `emailCopy`,
        headers: {
          "content-type": "application/json",
        },
      };

      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        let dataAux = {
          emc_id: 0,
          emc_user_id: 0,
          emc_mimeType: 0,
          emc_contentText: "",
          emc_displayFrom: "",
          emc_envelopFrom: "",
          emc_subject: subject[0],
          emc_subjects: subject,
          emc_contentHTML: html,
          emc_json: JSON.stringify(design),
          emc_status: 0,
          emc_bla_id: 0,
          emc_creationDate: new Date(),
          emc_ser_id: 0,
          emc_type: 0,
          emc_expireDate: null,
          emc_addArticlesSuggestion: false,
          emc_sep_id: senderProfile,
          emc_order: 0,
          emc_trackExternalLinkClicks: isTracking,
        };

        if (props.emailCopy) {
          options.method = "PUT";
          dataAux.emc_id = props.emailCopy.emc_id;
          dataAux.emc_user_id = props.emailCopy.emc_user_id;
          dataAux.emc_creationDate = props.emailCopy.emc_creationDate;
          dataAux.emc_order = props.emailCopy.emc_order;
          options.data = JSON.stringify([dataAux]);
        } else options.data = JSON.stringify(dataAux);

        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            clearVariables();
            props.onDone(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const sendTest = () => {
    if (simpleValidator.current.allValid() && senderProfile >= 0) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };

      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          bro_id: 0,
          bro_emc_id: 0,
          name: "",
          displayfrom: "",
          fromaddress: "",
          subjects: selectedSubject.split("|"),
          html: html,
          pauseStandard: false,
          date: Moment(),
          bro_segments: null,
          recipient: testEmail,
          trigger_events: null,
          bro_senderProfile: JSON.stringify(
            arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
          ),
          bro_tracking: isTracking,
        });
        client
          .request(options)
          .then((response) => {
            setOpenTestSB(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      if (senderProfile < 0) simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900px", // or percent "50%"
    });
    unlayer.registerCallback("previewHtml", async function (params, done) {
      const options = {
        method: "POST",
        url: `emailCopy/Preview`,
        headers: {
          "content-type": "application/json",
        },
      };
      options.data = JSON.stringify({
        subject: subject[0],
        body: params.html,
      });
      client
        .request(options)
        .then((response) => {
          done({
            html: response.body,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    });

    unlayer.setMergeTags({
      title: {
        name: "Title",
        value: "[title]",
        sample: "[title]",
      },
      currentDate: { name: "Current date", value: "[current-date]", sample: "[current-date]" },
      broadcastId: { name: "Broadcast ID", value: "[broadcast-id]", sample: "[broadcast-id]" },
      email: { name: "Email", value: "[email]", sample: "[email]" },
      phone: { name: "Phone", value: "[phone]", sample: "[phone]" },
      firstName: { name: "First Name", value: "[first]", sample: "[first]" },
      lastName: { name: "Last Name", value: "[last]", sample: "[last]" },
      city: { name: "City", value: "[city]", sample: "[city]" },
      state: { name: "State", value: "[state]", sample: "[state]" },
      country: { name: "Country", value: "[country]", sample: "[country]" },
      optinDate: { name: "Optin Date", value: "[optin-date]", sample: "[optin-date]" },
      contactUsUrl: {
        name: "Contact Us URL",
        value: "[contact-us-url]",
        sample: "[contact-us-url]",
      },
      contactUsName: {
        name: "Contact Us Name",
        value: "[contact-us-name]",
        sample: "[contact-us-name]",
      },
      companyName: { name: "Company Name", value: "[company-name]", sample: "[company-name]" },
      companyAddress: {
        name: "Company Address",
        value: "[company-address]",
        sample: "[company-address]",
      },
      companyCountry: {
        name: "Company Country",
        value: "[company-country]",
        sample: "[company-country]",
      },
      unsubscribeUrl: {
        name: "Unsubscribe URL",
        value: "[unsubscribe-url]",
        sample: "[unsubscribe-url]",
      },
      unsubscribeParagraph: {
        name: "Unsubscribe Paragraph",
        value: "[unsubscribe-paragraph]",
        sample: "[unsubscribe-paragraph]",
      },
    });

    if (jsonDesign != "") unlayer?.loadDesign(jsonDesign);
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setArrSenderProfiles(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (senderProfile >= 0) {
      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else setShowTracking(false);
  }, [senderProfile]);

  useEffect(() => {
    getCatalogs();
    getSenderProfiles();
  }, []);

  useEffect(() => {
   // console.log("props.emailCopy", props.emailCopy);
    if (props.emailCopy) {
      setSenderProfile(props.emailCopy.emc_sep_id);
      setSubject(
        Array.isArray(props.emailCopy.emc_subjects)
          ? props.emailCopy.emc_subjects
          : [props.emailCopy.emc_subjects]
      );
      setJsonDesign(JSON.parse(props.emailCopy.emc_json));
      setIsTracking(true);
    } else {
      setSenderProfile(-1);
      setSubject([""]);
      setJsonDesign("");
      setIsTracking(false);
      setShowTracking(false);
    }
  }, [props.emailCopy]);

  /*
  useEffect(() => {
    if (senderProfile >= 0) {
      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [senderProfile]);
  */

  const handleChangeSubject = (subject) => {
    setSubject(subject);
    setSelectedSubject(subject[0]);
  };

  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      fullScreen
      style={{ marginLeft: 300 }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Create an email copy
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2}>
                <Card xs={12} style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
                  <CardHeader title="Subjects" />
                  <MDBox mb={2} display="flex">
                    {subject && (
                      <MSMultipleSubjects
                        subjects={subject}
                        setSubjects={(value) => handleChangeSubject(value)}
                      />
                    )}
                    <MDBox ml={2}>
                      <MSPersonalizationTags />
                    </MDBox>
                  </MDBox>
                  <MDBox color="red">
                    {simpleValidator.current.message("Subject", subject, "required")}
                  </MDBox>
                </Card>
              </MDBox>

              {arrSenderProfiles.length > 0 && (
                <MDBox mb={2}>
                  <Select
                    placeholder="Select sender profile"
                    value={senderProfile}
                    onChange={(e) => {
                      setSenderProfile(e.target.value);
                    }}
                  >
                    <MenuItem key={-1} value={-1}>
                      <ListItemText primary={"Select a sender profile"} />
                    </MenuItem>
                    {arrSenderProfiles.map((item) => (
                      <MenuItem key={item.sep_id} value={item.sep_id}>
                        <ListItemText
                          primary={"From:" + item.sep_envelopFrom}
                          secondary={"Reply to:" + item.sep_replyTo}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDBox color="red">
                    {simpleValidator.current.message("Sender Profile", senderProfile, "sender")}
                  </MDBox>
                </MDBox>
              )}
              <MDBox display="flex" justifyContent="left">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTracking}
                      onChange={() => setIsTracking(!isTracking)}
                      disabled={!showTracking}
                    />
                  }
                  label="Track opens and clicks"
                />

                <Tooltip
                  id="descriptionHelp"
                  title={
                    <MDTypography color={"white"}>
                      Track opens and clicks, only if the domain are verified for tracking
                    </MDTypography>
                  }
                >
                  <Icon style={{ marginTop: 8, marginLeft: 2 }}>info</Icon>
                </Tooltip>
              </MDBox>
            </Card>

            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <Grid container spacing={2}>
                <Grid item mb={2} xs={10}>
                  <MDTypography variant="h5" fontWeight="medium" mt={1}>
                    HTML content
                  </MDTypography>
                </Grid>
                <Grid item xs={2} style={{ width: "100%", textAlign: "right" }}>
                  <MSPersonalizationTags />
                </Grid>
                <Grid item xs={12}>
                  <EmailEditor
                    ref={emailEditorRef}
                    onReady={onReady}
                    projectId={localStorage.getItem("unlayerID")}
                    minHeight={600}                    
                    appearance={{
                      panels: {
                        tools: {
                          dock: "left",
                          tabs: {
                            body: {
                              visible: true,
                            },
                          },
                        },
                      },
                      features: {
                        preview: true,
                      },
                    }}
                    tabs={{
                      image: {
                        enabled: false,
                      },
                    }}
                    options={{
                      customJS: [
                        window.location.protocol + "//" + window.location.host + "/customUrl.js",
                        !features.hiddenMenus.includes("catalogs")
                          ? window.location.protocol +
                            "//" +
                            window.location.host +
                            "/customTool.js"
                          : "",
                      ],
                      editor: {
                        autoSelectOnDrop: true,
                      },
                      tools: {
                        // my_tool is the name of our custom tool
                        // It is required to add custom# before the name of your custom tool
                        "custom#variables_tool": {
                          properties: {
                            catalog: {
                              editor: {
                                data: {
                                  catalogs: catalogs,
                                  token: token,
                                  domain: process.env.REACT_APP_MAIN_DOMAIN,
                                },
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          </MDBox>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <Grid>
              <MDInput
                onChange={(event) => handleSendTest(event)}
                label="e-mails to test"
                style={{ marginBottom: 10, width: "100%" }}
              />
              {subject && (
                <MDBox display="flex">
                  <MDTypography>Select a subject line </MDTypography>
                  <Select
                    title="Select a subject line"
                    value={selectedSubject}
                    onChange={(e) => {
                      setSelectedSubject(e.target.value);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    {subject.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDButton
                    style={{ marginLeft: 10 }}
                    variant="gradient"
                    color="success"
                    onClick={sendTest}
                    disabled={isLoading}
                  >
                    Send
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sent successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default EmailCopy;

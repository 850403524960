// https://dailyfix.org

import client from "ApiClient";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

/* eslint-disable */
export default function AdditionalContent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { openModal, closeModal } = props;
  const [adcID, setAdcID] = useState([]);
  const [qod, setQod] = useState("");
  const [tq, setTq] = useState("");
  const [jotd, setJotd] = useState("");
  const [adcif, setAdcif] = useState("");
  const [qaa, setQaa] = useState("");
  const [totd, setTotd] = useState("");
  const [slh, setSlh] = useState("");

  useEffect(() => {
    clearData();
    if (props.blaId > 0) getData();
  }, [props.blaId]);

  const clearData = () => {
    setAdcID("");
    setQod("");
    setTq("");
    setJotd("");
    setAdcif("");
    setQaa("");
    setTotd("");
    setSlh("");
  };

  const options = {
    method: "GET",
    url: "emailIntros",
    headers: {
      "content-type": "application/json",
    },
  };

  const generate = async () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `blogArticles/GenerateAdditionalContent?bla_id=${props.blaId}&topic=${props.article}`,
    };
    // console.log(options.data);
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `emailIntros/additionalContent?bla_id=${props.blaId}`;
    await client
      .request(options)
      .then((response) => {
        setAdcID(response.adc_id);
        setQod(response.adc_qod);
        setTq(response.adc_tq);
        setJotd(response.adc_jotd);
        setAdcif(response.adc_if);
        setQaa(response.adc_qaa);
        setTotd(response.adc_totd);
        setSlh(response.adc_slh);
        // console.log(JSON.parse(response.adc_tq));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const savePost = async () => {
    setIsLoading(true);
    const options = {
      method: "PUT",
      url: `emailIntros/additionalContent`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      adc_id: adcID,
      adc_bla_id: props.blaId,
      adc_qod: qod,
      adc_tq: tq,
      adc_jotd: jotd,
      adc_if: adcif,
      adc_qaa: qaa,
      adc_totd: totd,
      adc_slh: slh,
    });

    // console.log(options.data);
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const handleChangeQod = (event) => {
    setQod(event.target.value);
  };

  const handleChangeTq = (event) => {
    setTq(event.target.value);
  };

  const handleChangeJotd = (event) => {
    setJotd(event.target.value);
  };

  const handleChangeIf = (event) => {
    setAdcif(event.target.value);
  };

  const handleChangeQaa = (event) => {
    setQaa(event.target.value);
  };

  const handleChangeTotd = (event) => {
    setTotd(event.target.value);
  };

  const handleChangeSlh = (event) => {
    setSlh(event.target.value);
  };

  return (
    <div>
      <Dialog open={openModal} onClose={closeModal} fullScreen>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="xs"
          coloredShadow="info"
          p={2}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Additional content for "{props.article}"
          </MDTypography>
        </MDBox>
        <DialogContent>
          <MDBox
            p={5}
            sx={{
              //You can copy the code below in your theme
              background: "#FFFFFF",
              "& .MuiPaper-root": {
                background: "#FFFFFF",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "transparent", // Try to remove this to see the result
              },
            }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Qoute of the day:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {qod}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Trivia questions:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {tq}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Joke of the day:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {jotd}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Interesting facts:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {adcif}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Question and answer:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {qaa}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Tip of the day:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {totd}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <MDTypography variant="h4" fontWeight="medium">
                  Secret little hack:
                </MDTypography>
                <MDTypography variant="body1" ml={1} mr={2} color="text">
                  {slh}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "white" }}>
          <MDButton
            autoFocus
            onClick={closeModal}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Close
          </MDButton>

          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
/*
 <Button autoFocus onClick={generate} disabled={isLoading}>
                Generate
              </Button>
              <Button onClick={savePost} disabled={isLoading} autoFocus>
                Save
              </Button>
              */

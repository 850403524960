/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import moment from "moment";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import client from "ApiClient";
import ConfirmationAlert from "components/ConfirmationAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Edit from "./edit";
import CustomToolbarSelect from "./customToolbarSelect";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [formCode, setFromCode] = useState("");
  const [openCode, setOpenCode] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "blogs",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `forms`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setForms(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getHTMLCode = (form) => {
    const options = {
      method: "POST",
      url: `forms/preview`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify(form[0]);

    client
      .request(options)
      .then((response) => {
        setFromCode(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!openLog) {
      getData();
    }
  }, [openLog]);

  useEffect(() => {
    getData();
  }, [props.groupAdded]);

  const handleEdit = () => {
    handleOpenModal();
  };
  const getDateValue = (date) => {
    return moment(date).format("L");
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `forms?form_id=${selectedForm[0].form_id}`;

    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenAlert(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "Name",
      name: "form_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Date created",
      name: "form_creationDate",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "form_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedForm([forms[dataIndex]]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  // setSelectedForm(forms[dataIndex]);
                  getHTMLCode([forms[dataIndex]]);
                  setOpenCode(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="View code">
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any forms",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "forms/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogs.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      setSelectedForm([]);
      allRowsSelected.map((row) => {
        setSelectedForm((prev) => [...prev, forms[row.dataIndex]]);
      });
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this form?"
      />
      <Edit
        openModal={openEdit}
        onClose={() => setOpenEdit(false)}
        form={selectedForm[0]}
        onDone={() => getData()}
      />
      <Dialog open={openCode} onClose={() => setOpenCode(false)} fullWidth>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="xs"
          coloredShadow="primary"
          p={2}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Form code for {selectedForm?.form_name}
          </MDTypography>
        </MDBox>
        <DialogContent>
          <CopyToClipboard text={formCode}>
            <IconButton sx={{ fontWeight: "bold" }} color="primary" aria-label="prompt">
              <Tooltip id="button-report" title="Copy to clipboard">
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>
          </CopyToClipboard>
          <MDInput type="text" fullWidth multiline value={formCode} />
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton onClick={() => setOpenCode(false)} variant="outlined" color="secondary">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="xs"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Forms
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new form">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  Create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={forms} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

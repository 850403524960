import { useRef, useState } from "react";
// react-router-dom components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import { InputAdornment } from "@material-ui/core";

/* eslint-disable */
function AddSenderDomain(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [sendingDomain, setSendingDomain] = useState("");
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const clearVariables = () => {
    setDomainName("");
    setSendingDomain("");
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `senderDomains`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        sed_domain: domainName,
        sed_sendingDomain: sendingDomain == "" ? domainName : sendingDomain + "." + domainName,
      });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Dialog open={openModal} onClose={clearVariables}>
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white">
          Sending domain authentication
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox pt={2} pb={2}>
            <MDInput
              type="text"
              placeholder="Domain name"
              label="Domain name"
              value={domainName}
              fullWidth
              onChange={(e) => setDomainName(e.target.value)}
            />
            <MDTypography variant="caption">
              The name that was purchased or registered for your business. For instance, the domain
              name for name@company.com is "company.com".
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Sending domain"
              label="Sending domain"
              value={sendingDomain}
              onChange={(e) => setSendingDomain(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">.{domainName}</InputAdornment>,
              }}
            />
            <MDTypography variant="caption">
              The sending domain is the domain that appears in your email's "From" field. It is also
              linked to your reputation and is used in authentication mechanisms like SPF, DKIM or
              DMARC. In the Sending Domain field, specify the subdomain you created for sending your
              email broadcasts.
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default AddSenderDomain;
